import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/assets/img/logo1.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ManagementCourseBG from 'src/assets/bdslandingpage/banner1.jpg';
import Scope1 from 'src/assets/bdslandingpage/Scope-1.png';
import Scope2 from 'src/assets/bdslandingpage/Scope-2.png';
import Scope3 from 'src/assets/bdslandingpage/Scope-3.png';
import Scope4 from 'src/assets/bdslandingpage/Scope-4.png';
import Scope5 from 'src/assets/bdslandingpage/Scope-5.png';
import Scope6 from 'src/assets/bdslandingpage/Scope-6.png';
import banner2 from 'src/assets/bdslandingpage/banner2.jpg';
import Shetty from 'src/assets/bdslandingpage/A B Shetty.png';
import JSS from 'src/assets/bdslandingpage/JSS.png';
import KIMS from 'src/assets/bdslandingpage/KIMS.png';
import KLE from 'src/assets/bdslandingpage/KLE.png';
import Manipal from 'src/assets/bdslandingpage/Manipal.png';
import MR from 'src/assets/bdslandingpage/MR.png';
import Oxford from 'src/assets/bdslandingpage/Oxford.png';
import RajaRajeswari from 'src/assets/bdslandingpage/RajaRajeswari.png';
import Ramaiah from 'src/assets/bdslandingpage/Ramaiah.png';
import SDM from 'src/assets/bdslandingpage/SDM.png';
import Vydehi from 'src/assets/bdslandingpage/Vydehi.png';
import Yenepoya from 'src/assets/bdslandingpage/Yenepoya.png';
import contactbanner from 'src/assets/bdslandingpage/BDSBanner.jpg';
import Direct from 'src/assets/bdslandingpage/Direct.png';
import Lowest from 'src/assets/bdslandingpage/Lowest.png';
import Step from 'src/assets/bdslandingpage/Step.png';
import Scholarship from 'src/assets/bdslandingpage/Scholarship.png';


import "./styles.css";
import 'animate.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const BscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();



  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    // console.log(data ,"data")
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('neetrank', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'BDS Admission 2025-26');

//   alert(JSON.stringify(values));
    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);

      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [expanded, setExpanded] = useState(false);

  const navigate = (id) => {
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
    setExpanded(false); // Collapse the navbar after clicking any link
  };
  return (


    <>
      <Helmet>
        <title>BDS Admission in Karnataka 2024-25</title>
        <meta name="description"
          content="Get started with BDS Admission in Karnataka 2024-25. Check out BDS course details, scope of BDS, Top Dental Colleges in Karnataka, Best Dental Colleges in Bangalore, & more. Apply Now!" />
        <link rel="canonical" href="https://bangalorestudy.com/bds-admission" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      neetrank: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "BDS Admission 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "Is there any entrance exam for BDS Admission in Karnataka?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, candidates have to compulsorily qualify the National Eligibility cum Entrance Test - Undergraduate (NEET-UG) exam to be eligible for BDS Admission in Karnataka/ BDS Admission in Bangalore."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the BDS Eligibility Criteria?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": " BDS eligibility criteria is completion of 10+2/ 2nd PUC with English, 50% aggregate marks in PCB subjects, along with qualification in NEET-UG entrance exam."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the BDS course duration?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "BDS course duration is 5 years which includes 4 years of study followed by 1 year of compulsory rotatory internship."
                  }
                }, {
                  "@type": "Question",
                  "name": "How much is the BDS course fees?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "BDS course fees varies drastically based on the type of seat, i.e. Merit or Management and differs from college to college. BDS course fees for Indian nationals and BDS NRI quota fees are also different."
                  }
                }, {
                  "@type": "Question",
                  "name": "What are some of the Best Private Dental Colleges in Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Faculty of Dental Sciences at Ramaiah University of Applied Sciences (RUAS), Vokkaligara Sangha Dental College & Hospital (KIMS Campus), and Vydehi Institute of Dental Sciences & Research Centre are some of the Best Private Dental Colleges in Bangalore."
                  }
                }
                ]
            }
          )}
        </script>

      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" className="bg-body-tertiary" variant="light" expanded={expanded}>
          <Container fluid>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="BDS Admission in Karnataka 2024-25"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link onClick={() => { navigate('home'); }}>Home</Nav.Link>
                <Nav.Link onClick={() => { navigate('whychoose'); }}>Why Us?</Nav.Link>
                <Nav.Link onClick={() => { navigate('bdsdetail'); }}>BDS Course Details</Nav.Link>
                <Nav.Link onClick={() => { navigate('scope'); }}>Scope of BDS</Nav.Link>
                <Nav.Link onClick={() => { navigate('bdsaddmission'); }}>BDS Admissions</Nav.Link>
                <Nav.Link onClick={() => { navigate('topcollege'); }}>Top Colleges</Nav.Link>
                <Nav.Link onClick={() => { navigate('FAQs'); }}>FAQs</Nav.Link>
                <Nav.Link onClick={() => { navigate('Contact'); }}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0" id="home" style={{ paddingTop: 95 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bgimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className=' slide-in'>
                  <h1 className='text-center h1BDS '>BDS Admission in Karnataka 2024-25
                    {/* <span className='d-block'>Top Dental Colleges, Low BDS Course Fees</span > */}
                    <span className='span pt-2'>Top Dental Colleges, Low BDS Course Fees</span>
                  </h1>
                  <p className='text-center p  mt-2 font23' >Make Your Way into the Top Dental Colleges in Karnataka with <br />  our Expert Assistance for BDS Admission in Karnataka and <br />  BDS Admission in Bangalore.
                  </p>
                  <Button variant="primary" className="btn btn-success mb-4 btnbds" onClick={handleShow}>
                    Connect with our Experts!
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-box p-md-4 slide-in2">
                  <div>
                    <h3
                      className="f700 pb-3"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Fill in Your Details to Get Started.


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>
                    {/* <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control "
                        name="course_name"
                        id="course_name"
                        required
                      >
                        <option value="" >
                          Choose Course
                        </option>
                        <option value="BBA">
                          BBA
                        </option>
                        <option value="BCA">
                          BCA

                        </option>
                        <option value="B.Com">
                          B.Com
                        </option>
                        <option value="B.Sc">
                          B.Sc
                        </option>
                        <option value="BOT">
                          BOT

                        </option>
                        <option value="BPT">
                          BPT
                        </option>
                        <option value=" BHA">
                          BHA

                        </option>
                        <option value="BVA">
                          BVA
                        </option>
                        <option value="M.Sc Integrated">
                          M.Sc Integrated
                        </option>
                        <option value="MOT Integrated">
                          MOT Integrated
                        </option>
                        <option value="DHA">
                          DHA
                        </option>
                        <option value="M.Sc">
                          M.Sc
                        </option>
                        <option value="MPT">
                          MPT
                        </option>
                        <option value="MOT">
                          MOT
                        </option>
                        <option value="MHA">
                          MHA
                        </option>
                      </select>
                    </div> */}
                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" id="whychoose" >
                      <button className="btn btn-success submit btnbdscolour" name="submit" defaultValue="Submit" type="submit">Submit</button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="osec-with-right-form pb-0 pt-4" style={{ backgroundColor: "rgb(18 34 55)", color: "white" }}  >


        <div className="container-fluid " >
          <div className='row px-0 px-md-3'>
            <div className='col-12 bdsh2why h2 ' >Why Us?

            </div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              {/* <i
                className="fa fa-bars"
                style={{ fontSize: 100, marginBottom: 17 }}
              /> */}
              <img src={Direct} className='bdsimg4' alt='Direct Admission in Top Dental Colleges in Karnataka'></img>
              <h3 className='bdscyellow' >Direct Admission in Top Dental Colleges in Karnataka</h3>
              <p className='pt-3 pb-3  pb-md-4'>
                Qualified NEET-UG? Make use of this opportunity to get Direct BDS Admission in the Best Dental Colleges in Bangalore, as well as in the Top BDS Colleges in Karnataka.
              </p>
            </div></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <img src={Lowest} className='bdsimg4' alt='Lowest BDS Course'></img>
              <h3 className='bdscyellow' >Lowest BDS Course<br /> Fees Packages</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'>Taken aback by BDS Course Fees? Connect with our experts to secure BDS Admission in Top Dental Colleges with BDS Course Fees Much Less than those allotted through Counselling.
              </p>
            </div></div>

            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <img src={Step} className='bdsimg4' alt='Step-by-Step NEET Counselling Assistance'></img>

              <h3 className='bdscyellow'>Step-by-Step NEET Counselling Assistance</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4' >
                Right from Registration, Choice Filling/ Option Entry to Seat Allotment & beyond, we offer complete NEET Counselling Assistance to ensure your successful BDS admission.

              </p>
            </div></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <img src={Scholarship} className='bdsimg4' alt='Scholarship'></img>
              <h3 className='bdscyellow'>Scholarship for <br />BDS Students</h3>
              <p className='pt-2 pb-3 pt-md-4 pb-md-4 pt-xl-5' >
                High BDS Course Fees <span id="bdsdetail" style={{ display: "hidden" }}></span> shouldn’t stop you from pursuing your dream. Reach out to us now for details about our Exclusive Scholarship for BDS students!
                

              </p>

            </div>
            </div>


          </div>

        </div>
      </section >

      <section className="container-fluid  pb-0 pt-4"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-12 pb-1  pb-md-4">
              <h3 className='fh3BDS text-center'>BDS Course Details
              </h3>
            </div>
            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>Are you aspiring to become a Dentist? Or you’ve just written the NEET exam and are wondering <br />which course to opt for? Before we get started with the BDS Admission Process,<br /> let’s get familiar with the basics of this highly sought-after Dental course.</p></div>



            <div className="col-lg-12 px-0 px-md-5 ">
              <div className=" customborder1">
                <table className="table table-bordered">
                  <tbody>

                    <tr>
                      <th scope="row">Course Name</th>
                      <td colSpan={3}>Bachelor of Dental Surgery (BDS)</td>

                    </tr>
                    <tr>
                      <th scope="row">Course Level</th>
                      <td colSpan={3}>Undergraduate (UG)</td>

                    </tr>
                    <tr>
                      <th scope="row">Duration</th>
                      <td colSpan={3}>5 Years
                        <p>(4 Year Academic Study + 1 Year Internship)</p></td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}>NEET-UG</td>

                    </tr>
                    <tr>
                      <th scope="row">BDS Eligibility Criteria</th>
                      <td colSpan={3} className='d-flex justify-content-center'>
                        <ul className='text-start ps-4'>
                          <li>Passed 10+2/ 2nd PUC with English and <br /> 50% Aggregate Marks in PCB Subjects</li>
                          <li>Qualified NEET-UG entrance exam</li>
                          <li>17 Years of age as on Dec 31st in the Admission Year</li>
                        </ul>
                      </td>

                    </tr>
                    <tr>
                      <th scope="row">Regulatory Body</th>
                      <td colSpan={3}>Dental Council of India (DCI)</td>

                    </tr>
                  </tbody>
                </table>


              </div>
            </div>

          </div>

        </div >
      </section >

      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0" >
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items bdsimgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${banner2})` }}>
          <div className='dd pt-5'><h2 className='bgblack p-3'>Stay Informed about the Latest Updates on <br />NEET-UG Counselling 2024-25, Schedules, Seat Matrix, and more.</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5" onClick={handleShow}>
              Connect with our Experts!
            </Button>
          </div>
        </div>


      </section>

      <section className="container-fluid  p-0 pb-0 pt-3" >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='fh3BDS'>BDS Syllabus</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs '>BDS syllabus in India is designed in a way that covers all fundamental aspects of Dental Health <br className='d-none d-md-block' />and Dental Treatments. It is a 5-year course with 4 years of academic study followed by 1 year<br className='d-none d-md-block' /> of compulsory rotatory internship. This module equips students with the necessary knowledge<br className='d-none d-md-block' /> and skills to begin their career as Dentist.<br />  <br />Studying BDS from the Top Dental Colleges in Karnataka allows students to learn and get <br className='d-none d-md-block' /> trained from the best Dental faculty of the country and puts them ahead of their peers.</p></div>
            <div className="col-lg-12 text-center pt-3 pb-3">

              <div className="row row-cols-2 row-cols-md-4 g-2 customborder2 customborder3">
                <div className="col">
                  <div className="card   text-bg-success  bgcc  text-center ">
                    <div className="card-header">
                      <h4 className="card-title ">1st Year</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start font-bds'>
                        <li>General Human Anatomy</li>
                        <li>General Human Physiology</li>
                        <li>Biochemistry, Nutrition &amp; Dietetics</li>
                        <li>Dental Anatomy</li>
                        <li>Embryology &amp; Oral Histology</li>
                        
                      </ul>
                      <Button variant="primary" className="btn btn-success mb-4 bdssyaalbus-padding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>
                <div className="col">
                  <div className="card   text-bg-success  bgcc  text-center ">
                    <div className="card-header">
                      <h4 className="card-title ">2nd Year</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start font-bds'>
                        <li>General Pathology and Microbiology</li>
                        <li>Dental Pharmacology & Therapeutics</li>
                        <li>Preclinical Conservative Dentistry</li>
                        <li>Preclinical Prosthodontics and Crown & Bridge</li>
                       
                      </ul>

                      <Button variant="primary" className="btn btn-success mb-4 bdssyaalbus-padding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>
                <div className="col">
                  <div className="card   text-bg-success  bgcc  text-center ">
                    <div className="card-header">
                      <h4 className="card-title ">3rd Year</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start font-bds'>
                        <li>General Medicine</li>
                        <li>General Surgery</li>
                        <li>Oral Pathology and Oral Microbiology</li>
                        <li>Conservative Dentistry & Endodontics</li>
                        <li>Oral & Maxillofacial Surgery</li>
                     
                      </ul>

                      <Button variant="primary" className="btn btn-success mb-4 bdssyaalbus-padding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>
                <div className="col">
                  <div className="card   text-bg-success  bgcc  text-center ">
                    <div className="card-header">
                      <h4 className="card-title ">4th Year</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start font-bds'>
                        <li>Orthodontics & Dentofacial Orthopaedics
                        </li>
                        <li>Paediatric & Preventive Dentistry
                        </li>
                        <li>Periodontology</li>
                        <li>Oral & Maxillofacial Surgery</li>
                        <li>Prosthodontics and Crown & Bridge</li>
                       
                      </ul>

                      <Button variant="primary" id="scope" className="btn btn-success mb-4 bdssyaalbus-padding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>
              </div>
            </div>


          </div>



        </div>
      </section >

      <section className="container-fluid  pb-0 pt-4" >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center bdsscope bdsscop1  pb-2">
            <div className="col-lg-12 pb-1  pb-md-4">
              <h3 className='fh3BDS text-center'>Scope of BDS
              </h3>
            </div>
            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>For a BDS graduate, the field of Dentistry offers vast scope. Right from beginning their own private practice to specialising in a field of their interest, a student has quite a few options to choose from. Nevertheless, studying in a Top Dental College in Karnataka increases your scope and ensures a bright career ahead.</p></div>



            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope1} alt="Begin Own Clinical Practice" />
              <p className='bdsscop0'>Begin Own Clinical Practice</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope2} alt=" Work for Leading Dental Chains and Hospitals" />


              <p className='bdsscop0'>Work for Leading Dental Chains and Hospitals</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope3} alt="Well-paying Jobs in Government Sector" />


              <p className='bdsscop0'>Well-paying Jobs in Government Sector</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope4} alt="Specialize with a Master of Dental Surgery (MDS)" />


              <p className='bdsscop0'> Specialize with a Master of Dental Surgery (MDS)</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope5} alt="Pursue Clinical Research" />


              <p className='bdsscop0'>Pursue Clinical Research</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope6} alt="MBA in Public Health, Hospital Administration, etc." />


              <p className='bdsscop0' id="bdsaddmission">MBA in Public Health, Hospital Administration, etc.</p>
            </div>

          </div>

        </div >
      </section >

      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='fh3BDS'>BDS Admissions</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>Securing BDS Admission in India can be a task on its own. NEET-UG is a compulsory entrance exam that anyone who aspires to study BDS in India has to qualify in. It’s essential to have certain documents and certificates to proceed with the BDS Admission process. Let’s look at the list of documents required for BDS Admission in Karnataka/ BDS Admission in Bangalore that candidates must keep ready.</p></div>
            <div className="col-lg-12 text-center pt-3">
              <h3 className='fh3BDS font23'>Documents Required for BDS Admission 2024-25</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3">
              <div className="customborder1 border-none mx-md-5 mx-0">
                <table className="table table-bordered documents">
                  <tbody>
                    <tr className="d-flex flex-column  flex-md-row justify-content-center ">
                      <td className='pb-0 '>
                        <ul className='ps-3 ps-md-5 text-start '>
                          <li>10th, 11th and 12th Marksheets</li>
                          <li>Graduation Marksheets <span className='fontweigth400'>(if any) </span></li>
                          <li>NEET-UG Application Form</li>
                          <li>NEET-UG Admit Card</li>
                          <li>NEET-UG Scorecard</li>
                          <li>NEET-UG Rank Letter</li>
                          <li>Valid Government-issued ID Proof</li>
                        </ul>

                      </td>
                      <td className='pt-0  pt-md-2'>
                        <ul className='ps-3 ps-md-5 text-start' >
                          <li>Character Certificate</li>
                          <li>Transfer Certificate</li>
                          <li>Migration Certificate<span className='fontweigth400'> (if applicable)</span></li>
                          <li>Caste Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li id="topcollege">Income Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li>PwD Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li >Recent Passport Size Photographs</li>
                        </ul>

                      </td>


                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>



        </div>
      </section >

      <section className="container-fluid  p-0 pb-0" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='fh3BDS'>Top Dental Colleges <br className='d-block d-md-none'/> in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>The course we choose to study determines our career path. The college we choose to study determines our success in the chosen career path. The Top BDS Colleges in Karnataka have performed excellently well in the latest NIRF 2023 Rankings. Many of the Dental Colleges in Karnataka have bagged a place in the Top 40 Dental Colleges in India. Hence, studying from the acclaimed Dental Colleges in Karnataka is the best choice you can make for your future!<br />  <br />Here’s a list of the Top Dental Colleges in Karnataka that you should consider for your BDS Admissions 2024-25. Drop an enquiry to know more about these Top Dental Colleges in Karnataka!
            </p></div>
          </div>
          <div className="col-lg-12 text-center">
            <div className="row row-cols-1 row-cols-md-4 g-4 bdslandgingcolleges bdscollegeimage">


              <div className="col">
                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={Manipal} alt="Manipal College of Dental Sciences (MCODS)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">Manipal College of Dental Sciences (MCODS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Manipal & Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={Shetty} alt="A B Shetty Memorial Institute of Dental Sciences (ABSMIDS)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">A B Shetty Memorial Institute of Dental Sciences (ABSMIDS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={JSS} alt="JSS Dental College & Hospital" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">JSS Dental College & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mysore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={Ramaiah} alt="Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={SDM} alt="SDM College of Dental Sciences & Hospital" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">SDM College of Dental Sciences & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Dharwad
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={Yenepoya} alt="Yenepoya Dental College" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">Yenepoya Dental College</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={KLE} alt="KLE Vishwanath Katti Institute of Dental Sciences" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">KLE Vishwanath Katti Institute of Dental Sciences
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Belgaum
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={KIMS} alt="Vokkaligara Sangha Dental College & Hospital (KIMS Campus)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">Vokkaligara Sangha Dental College & Hospital (KIMS Campus)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker redi " aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={Vydehi} alt="Vydehi Institute of Dental Sciences & Research Centre (VIDSRC)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">Vydehi Institute of Dental Sciences & Research Centre (VIDSRC)
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={RajaRajeswari} alt="RajaRajeswari Dental College & Hospital (RRDCH)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">RajaRajeswari Dental College & Hospital (RRDCH)


                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={MR} alt="MR Ambedkar Dental College & Hospital (MRADC)" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">MR Ambedkar Dental College & Hospital (MRADC)



                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 align-items-center justify-content-center">
                  <img className="card-img-top imgcontain" src={Oxford} alt="The Oxford Dental College" />
                  <div className="card-body pt-0 pt-md-3">
                    <h5 className="card-title">The Oxford Dental College
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour"  onClick={handleShow}>
                      Enquire Now
                    </Button>
                    <span id ="FAQs"style={{ display: "hidden" }}></span>
                  </div>
                </div>
              </div>
              

            </div>
          </div>
          <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 20 }} >
            & Many More!</div>


        </div>
      </section >

      <div className="faq-section p-3 bg-gray-2" >
        <div className="container ">
          <div className="faq-header ">
            <h2 className='fh3BDS text-center'>
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img ">
              <img src="preprod/nursing_admission/assets/images/faq-img.png" alt="FAQs " />
            </div>
            <div
              className="faq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              {/* <div class=""> */}
              {/* <div class="faq-img">
                      <img src="preprod/nursing_admission/assets/images/faq.jpg "
                      alt="Helping hand in the College application process " width="100%"> 
                  </div>*/}
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Is there any entrance exam for BDS Admission in Karnataka?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Yes, candidates have to compulsorily qualify the National Eligibility cum Entrance Test - Undergraduate (NEET-UG) exam to be eligible for BDS Admission in Karnataka/ BDS Admission in Bangalore.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BDS Eligibility Criteria?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        BDS eligibility criteria is completion of 10+2/ 2nd PUC with English, 50% aggregate marks in PCB subjects, along with qualification in NEET-UG entrance exam.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BDS course duration?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        BDS course duration is 5 years which includes 4 years of study followed by 1 year of compulsory rotatory internship.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        How much is the BDS course fees?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        BDS course fees varies drastically based on the type of seat, i.e. Merit or Management and differs from college to college. BDS course fees for Indian nationals and BDS NRI quota fees are also different.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What are some of the Best Private Dental Colleges in Bangalore?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Faculty of Dental Sciences at Ramaiah University of Applied Sciences (RUAS), Vokkaligara Sangha Dental College & Hospital (KIMS Campus), and Vydehi Institute of Dental Sciences & Research Centre are some of the Best Private Dental Colleges in Bangalore.


                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contact">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='dd '><h2 className='bgblack p-3'>Begin your BDS Journey from a Top Dental College in Karnataka</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5 font-white23 " onClick={handleShow}>
              Apply Now!

            </Button>
          </div>
        </div>


      </section>

      <footer className='pb-3  bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <img src="preprod/nursing_admission/assets/images/footer-bg.png" className="imgfooter" alt="bangalorestudy " />
              </div>

              <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
                {" "}
                © 2025Bangalorestudy.com. All Rights Reserved.
              </p>
              </div>
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100 px-5  justify-content-end" >
              <div><p className=" font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696(Toll
                    Free)
                  </a>
                </div>
                <p >
                  <span style={{ fontSize: 20, fontWeight: 600 }}> Dubai </span> :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                  </a>
                </p>

              </div>
              <div className="text-center" >
                <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5 d-flex'>
                  <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                    <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                    <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                  >
                    {" "}
                    <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://instagram.com/bangalorestudy ">
                    <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://in.linkedin.com/company/learntech-edu-solutions-pvt-ltd"
                  >
                    {" "}
                    <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                    <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                  </a>
                </p>

              </div>
            </div>

          </div>

        </div>

        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left pt-4 col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
               <img src="preprod/nursing_admission/assets/images/footer-bg.png " className="imgfooter" alt="bangalorestudy " />
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex pt-0 mt-0">
            <div className="app-unit col-md-7  col-md-5 ">
              {/* <div className="store-wrapper">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div> */}
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696(Toll Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:970502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +970502436552{" "}
                  </a>
                  ,{" "}<br className='d-block' />
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +971504955123{" "}
                  </a>
                </p>
              </div>
              <div className="text-center" >
                <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5 d-flex'>
                  <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                    <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                    <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                  >
                    {" "}
                    <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://instagram.com/bangalorestudy ">
                    <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a
                    target="_blank "
                    href="https://in.linkedin.com/company/learntech-edu-solutions-pvt-ltd"
                  >
                    {" "}
                    <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                  </a>
                  <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                    <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                  </a>
                </p>

              </div>
            </div>
            <p className="pe-4">
              {" "}
              © 2025Bangalorestudy.com. All Rights Reserved.
            </p>

          </div>
        </div>
      </footer>
      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        <i className="bi bi-whatsapp my-float " />
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>Fill in the Details to Get in Touch with our Experts.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BscNursing;