import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/assets/img/logo1.png';
import Defaultlogo from 'src/assets/assets/img/logo1.png';
import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';


import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import MDSBanner from 'src/assets/MDS/MDSBanner.jpg';
import Shetty from 'src/assets/MDS/Shetty.png';
import JSS from 'src/assets/MDS/JSS.png';
import KLE from 'src/assets/MDS/KLE.png';
import Oxford from 'src/assets/MDS/Oxford.png';


import Ramaiah from 'src/assets/MDS/Ramaiah.png';
import SDM from 'src/assets/MDS/SDM.png';
import Vydehi from 'src/assets/MDS/Vydehi.png';
import Yenepoya from 'src/assets/MDS/Yenepoya.png';
import contactbanner from 'src/assets/MDS/Contactbanner.png';
import step1 from 'src/assets/MDS/Step1.png';
import step2 from 'src/assets/MDS/Step2.png';
import step3 from 'src/assets/MDS/Step3.png';
import step4 from 'src/assets/MDS/Step4.png';
import Manipal from 'src/assets/MDS/Manipal.png';
import Bapuji from 'src/assets/MDS/Bapuji.png';
import Pandu from 'src/assets/MDS/Pandu.png';
import Dayananda from 'src/assets/MDS/Dayananda.png';
import MDSSteps from 'src/assets/MDS/MDS.png';
import Prosthodontics from 'src/assets/MDS/MDS Bridge ICO.png';
import MaxillofacialSurgery from 'src/assets/MDS/Maxillofacial Surgery ICO.png';
import Pathology from 'src/assets/MDS/MDS Pathology.png';
import Orthodontics from 'src/assets/MDS/Orthodontics.png';
import Periodontology from 'src/assets/MDS/Periodontology.png';
import Dentistry from 'src/assets/MDS/Dentistry.png';
import Radiology from 'src/assets/MDS/Radiology.png';
import Conservative from 'src/assets/MDS/Conservative.png';
import Paedodontics from 'src/assets/MDS/Paedodontics.png';
import Faqimg from 'src/assets/PGDM/FAQ.png';


import PursueClinical from 'src/assets/MDS/PursueClinical.jpg';
import Job from 'src/assets/MDS/Jobs.jpg';
import Dental from 'src/assets/MDS/Dental.jpg';
import Lecture from 'src/assets/MDS/Lecturer.jpg';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import "./styles.css";
import 'animate.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const MDS: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'MDS Admission 2025-26');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);
  const [textmodal, setTextmodal] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setTextmodal("Fill in the Details to Get Started with the Admission Process.");

    setShow(true)
  };


  const handelshowlearn = () => {
    setTextmodal("Fill in the Details to Know More.");
    setShow(true)
  }
  const [expanded, setExpanded] = useState(false);

  const navigate = (id, offset = 0) => {
    const elementToView = document.getElementById(id);
    if (elementToView) {
      const offsetTop = elementToView.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth"
      });
      setExpanded(false); // Collapse the navbar after clicking any link
    } else {
      console.error("Element not found:", id);
    }
  };



  return (


    <>
      <Helmet>
        <title>MDS Admission in Karnataka 2024-25</title>
        <meta name="description"
          content="Get MDS Admission Guidance 2024-25. Know everything about the MDS course details, MDS eligibility criteria, Top MDS colleges in Karnataka, List of Dental colleges in Bangalore, and more. Apply Now!" />
        <link rel="canonical" href="https://bangalorestudy.com/mds-admission" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "MDS Admission 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "What is the procedure for MDS admission in Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Students need to qualify NEET-MDS and participate in the NEET-MDS counselling process conducted by MCC or KEA to acquire a seat in top MDS Colleges in Bangalore."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the MDS course duration and fees?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": " Master of Dental Surgery (MDS) is a 3-year postgraduate program in the field of Dentistry. The MDS course fees vary based on many factors such as location, infrastructure, the quota opted for, etc. Interested students can contact us for further details."
                  }
                }, {
                  "@type": "Question",
                  "name": "What are the MDS Specialisations offered at private Dental colleges in Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some of the MDS specialisations provided by private dental colleges in Bangalore are MDS Prosthodontics and Crown & Bridge, MDS Oral & Maxillofacial Surgery, MDS Orthodontics & Dentofacial Orthopedics, MDS Conservative Dentistry and Endodontics and many more."
                  }
                }, {
                  "@type": "Question",
                  "name": "Which are the best MDS Colleges in Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some of the best MDS Colleges in Bangalore are Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS), the Oxford Dental College, Vydehi Institute of Dental Sciences & Research Centre (VIDSRC), Dayananda Sagar College of Dental Sciences (DSCDS), and more."
                  }
                }, {
                  "@type": "Question",
                  "name": "What are some of the top Dental Colleges in Mangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some of the top Dental Colleges in Mangalore are Manipal College of Dental Sciences (MCODS), A B Shetty Memorial Institute of Dental Sciences (ABSMIDS), Yenepoya Dental College, A. J. Institute of Dental Sciences (AJIDS) and more."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the MDS fee structure in private colleges?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "For private Dental colleges in Bangalore, fee structure for MDS would vary based on certain factors. Contact us for more details regarding MDS course fees in Bangalore and MDS fees in Private Colleges in Karnataka."
                  }
                }
                ]
            }
          )}
        </script>

      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" className="bg-body-tertiary" variant="light" expanded={expanded}>
          <Container fluid className='px-0 px-md-2'>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="MDS Admission in Karnataka 2024-25"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse className='justify-content-end' id="responsive-navbar-nav ">
              <Nav className="ml-auto">
                <Nav.Link onClick={() => { navigate('home'); }}>Home</Nav.Link>
                <Nav.Link onClick={() => { navigate('Mds'); }}>What is MDS?</Nav.Link>
                <Nav.Link onClick={() => { navigate('study'); }}>Why Study MDS?</Nav.Link>
                <Nav.Link onClick={() => { navigate('admission', 80); }}>Admissions</Nav.Link>
                <Nav.Link onClick={() => { navigate('whyus', 95); }}>Why Us</Nav.Link>
                <Nav.Link onClick={() => { navigate('topdental', 70); }}>Top MDS Colleges</Nav.Link>
                <Nav.Link onClick={() => { navigate('FAQs', 40); }}>FAQs</Nav.Link>
                <Nav.Link onClick={() => { navigate('Contact', 50); }}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0" id="home" style={{ paddingTop: 95 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bgimage" id="home" style={{ background: `linear-gradient(86deg, rgb(0 0 0 / 77%), rgb(0 0 0 / 0%)) , url(${MDSBanner})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4 px-0 px-md-5" >
              <div className="col-xl-7 col-lg-7 col-md-7 pl-md-4  ">
                <div className=' slide-in p-md-5'>
                  <h1 className='h1MDS '>Enhance Your Skills <br /> in Dentistry
                    {/* <span className='d-block'>Top Dental Colleges, Low BDS Course Fees</span > */}

                  </h1>
                  <p className='admissionbgs  mt-2 font23 blink-text-christ' > Study in <br /> Top Dental Colleges in Karnataka.
                  </p>
                  <p className='mdsfont p-3 '>MDS Admissions Open for 2024-25</p>




                  <Button variant="primary" className="btn btn-success mt-4 mb-4   btnmdscolour d-block" onClick={handleShow}>
                    Apply Now
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-box p-md-4 slide-in2">
                  <div>
                    <h3
                      className="f700 pb-3"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Fill in Your Details to Get Started.


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" id="Mds" >
                      <button className="btn btn-success submit btnmdscolour" name="submit" defaultValue="Submit" type="submit">Submit</button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid  pb-0 pt-4"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-12 pb-1  pb-md-4">
              <h3 className='fh3MDS-what text-center'>What is MDS?
              </h3>
            </div>
            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>If you are someone who wishes to continue your education in Dentistry after completing <br className='d-none d-md-block' />Bachelor of Dental Surgery (BDS), then the Master of Dental Surgery program is the right <br className='d-none d-md-block' />course for you.</p></div>

            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>Master of Dental Surgery popularly known as MDS is a postgraduate program in the field of  <br className='d-none d-md-block' /> Dentistry. Various specialisations are available in MDS. In this course, students study about oral <br className='d-none d-md-block' /> health and its treatment methods based on the specialisation of their choice. </p></div>

            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>Let’s get familiar with some of the basics of the course before jumping into other details. </p></div>



            <div className="col-lg-12 pt-3 px-0 px-md-5 ">
              <div className="mds-customborder1 m-auto">
                <table className="table table-bordered">
                  <tbody>

                    <tr>
                      <th scope="row">Course Name</th>
                      <td colSpan={3}>Master of Dental Surgery (MDS)</td>

                    </tr>
                    <tr>
                      <th scope="row">Course Level</th>
                      <td colSpan={3}>Postgraduate (PG)</td>

                    </tr>
                    <tr>
                      <th scope="row">Duration</th>
                      <td colSpan={3}>3 Years
                      </td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}>NEET-MDS</td>

                    </tr>
                    <tr>
                      <th scope="row">MDS Eligibility Criteria</th>
                      <td colSpan={3} className='d-flex justify-content-center'>
                        <ul className='text-start ps-4'>
                          <li>Graduated  BDS from a Recognised University</li>
                          <li>Qualified NEET-MDS Entrance Exam</li>

                        </ul>
                      </td>

                    </tr>
                    <tr>
                      <th scope="row">Regulatory Body</th>
                      <td colSpan={3}>Dental Council of India (DCI)</td>

                    </tr>
                  </tbody>
                </table>


              </div>
            </div>

          </div>

        </div >
      </section >

      <section className="container-fluid  pb-0 pt-5" >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center bdsscope ">
            <div className="col-lg-12 pb-3  pb-md-4">
              <h3 className='fh3MDS text-center'>MDS Specialisations
              </h3>
            </div>
          </div>
          <div className='row gapMds px-0'>
            <div className='col-md-4 col-12'>
              <Card className='CardMDS col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={Prosthodontics} className="card-img-top pt-3" alt="MDS Prosthodontics and Crown & Bridge" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Prosthodontics and Crown & Bridge</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card>


            </div>


            <div className='col-md-4'>
              <Card className='CardMDS CardMDS1  col-md-4 card-background Card-width px-0' id="element1" >
                <div className='col-md-4 col-4 '>
                  <img src={Pathology} className="card-img-top pt-3" alt="MDS Oral Pathology & Microbiology" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Oral Pathology & Microbiology</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>

            <div className='col-md-4 col-12'>
              <Card className='CardMDS CardMDS2 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={MaxillofacialSurgery} className="card-img-top pt-3" alt="MDS Oral & Maxillofacial Surgery" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Oral & Maxillofacial Surgery</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>

            <div className='col-md-4 col-12'>
              <Card className='CardMDS CardMDS3 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={Orthodontics} className="card-img-top pt-3" alt="MDS Orthodontics & Dentofacial Orthopedics" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Orthodontics & Dentofacial Orthopedics</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>

            <div className='col-md-4 col-12'>
              <Card className='CardMDS CardMDS4 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={Periodontology} className="card-img-top pt-3" alt="MDS Periodontology" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS <br /> Periodontology</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>


            <div className='col-md-4 col-12'>
              <Card className='CardMDS CardMDS5 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={Dentistry} className="card-img-top pt-3" alt="MDS Public Health Dentistry" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Public Health Dentistry</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>


            <div className='col-md-4 col-12'>
              <Card className='CardMDS CardMDS6 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={Radiology} className="card-img-top pt-3" alt="MDS Oral Medicine & Radiology<" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Oral  Medicine & Radiology</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>


            <div className='col-md-4 col-12'>
              <Card className='CardMDS CardMDS7 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4  col-4 '>
                  <img src={Conservative} className="card-img-top pt-3" alt="MDS Conservative Dentistry & Endodontics" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <Card.Title className='font18MDS'>MDS Conservative Dentistry & Endodontics</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>


            <div className='col-md-4'>
              <Card className='CardMDS CardMDS8 col-md-4 card-background Card-width px-0' >
                <div className='col-md-4 col-4 '>
                  <img src={Paedodontics} className="card-img-top pt-3" alt="MDS Paedodontics & Preventive Dentistry<" />
                </div>


                <Card.Body className='col-md-8 col-8'>
                  <span id="study" style={{ display: "hidden" }}></span>
                  <Card.Title className='font18MDS'>MDS Paedodontics & Preventive Dentistry</Card.Title>
                  <Button variant="primary" className="btn btn-success btnmds" onClick={handelshowlearn}>
                    Learn More
                  </Button>
                </Card.Body>
              </Card></div>


          </div>


        </div >
      </section >

      <section className="container-fluid  pb-0 pt-4" >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center bdsscope ">
            <div className="col-lg-12 pb-3  pb-md-4">
              <h3 className='fh3MDS text-center'>Why Study MDS?
              </h3>
            </div>
            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>Dentistry has always been a good career choice. The demand for Dental care has considerably increased over the years. An MDS graduate can choose from a wide range of career options. Pursuing a degree from one of the best Dental colleges in Bangalore/ Karnataka is an added advantage for a lucrative career in Dentistry. </p></div>





          </div>

        </div >
        <div className='container-fluid  p-4'>
          <div className='container p-0'>
            <div className='row row-cols-4 g-0'>
              <div className='col-md-3 col-12 order-8 order-md-0 '>
                <Card className='h-100'>
                  <Card.Body className='card-font d-flex align-items-center justify-content-center card-bg'>
                    <h3 className='p-5'>Dental Surgeon in Hospitals & Clinics</h3>
                  </Card.Body>
                </Card>
              </div>
              <div className='col-md-3 col-12 order-1 order-md-0'>
                <Card className='h-100'>
                  <Card.Img variant="top" src={PursueClinical} alt="Pursue Clinical Research" />
                </Card>
              </div>
              <div className='col-md-3 col-12 order-2 order-md-0'>
                <Card className='h-100'>
                  <Card.Body className='card-font d-flex align-items-center justify-content-center card-bg'>
                    <h3 className='p-5'>Lecturer in Academic Institutions</h3>
                  </Card.Body>
                </Card>
              </div>
              <div className='col-md-3 col-12 order-5  order-md-0'>
                <Card className='h-100'>
                  <Card.Img variant="top" src={Job} alt="Jobs in Government Sectors" />
                </Card>
              </div>
              <div className='col-md-3 col-12  '>
                <Card className='h-100'>
                  <Card.Img variant="top" src={Dental} alt="Dental Surgeon in Hospitals & Clinics" />
                </Card>
              </div>
              <div className='col-md-3 col-12 order-6 order-md-0'>
                <Card className='h-100'>
                  <Card.Body className='card-font d-flex align-items-center justify-content-center card-bg'>
                    <h3 className='p-5'>Pursue Clinical Research</h3>
                  </Card.Body>
                </Card>
              </div>
              <div className='col-md-3 col-12  order-3 order-md-0'>
                <Card className='h-100' >
                  <Card.Img variant="top" src={Lecture} alt="Lecturer in Academic Institutions" />
                </Card>
              </div>
              <div className='col-md-3 col-12 order-4 order-md-0'>
                <Card className='h-100'>
                  <Card.Body className='card-font d-flex align-items-center justify-content-center card-bg' >
                    <h3 className='p-5' >Jobs in Government Sectors</h3>
                  </Card.Body>

                </Card>

              </div>
            </div>
          </div>
        </div>

      </section >

      <section className="container-fluid  pb-0 pt-4" id="admission" >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center bdsscope ">
            <div className="col-lg-12 pb-3  pb-md-4">
              <h3 className='fh3MDS-what text-center'> MDS Admission Process
              </h3>
            </div>
            <div className="col-lg-12 text-center  pb-3"><p className='textmbbs'>It is mandatory for students to qualify in the National Eligibility-cum-Entrance Test for Master of Dental Surgery (NEET-MDS) to study MDS anywhere in India. To get a seat in MDS colleges in Bangalore, students can participate in either MCC or KEA NEET-MDS counselling process. </p></div>

            <div className="col-lg-12 text-center  pb-3 pb-md-4"><p className='textmbbs'>The counselling process will have various rounds. Students will be asked to verify some documents as part of the counselling process.
            </p></div>

            <div className="col-lg-12 text-center  pb-3"><h4 className='textMds'>Documents Required for MDS Admission 2024-25</h4></div>
          </div>

      
          <div className="col-lg-12 text-center pt-3 pt-md-2 pb-3">
              <div className="customborder1 border-none mx-md-5 mx-0">
                <table className="table table-bordered documents">
                  <tbody>
                    <tr className="d-flex flex-column  flex-md-row justify-content-center ">
                      <td className='pb-0 '>
                        <ul className='ps-3 ps-md-5 text-start '>
                          <li>10th & 12th Marksheets</li>
                          <li>BDS Marks Card</li>
                          <li>Qualifying/ Provisional Degree Certificate</li>
                          <li>Internship Completion Certificate</li>
                          <li>NEET-MDS Registration Form</li>
                          <li>NEET-MDS Admit Card</li>
                          <li>NEET-MDS Scorecard</li>
                          <li>State/ Dental Council Registration Certificate</li>
                          <li>Valid Government-issued ID Proof</li>
                         
                        </ul>

                      </td>
                      <td className='pt-0  pt-md-2'>
                        <ul className='ps-3 ps-md-5 text-start' >
                          <li>Rural Service Bond Certificate</li>
                          <li>Certificate Stating College is Recognised by DCI</li>
                          <li>Migration Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li>Caste Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li id="topcollege">Income Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li> PwD Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li> NRI Ward Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li>RGUHS Certificate <span className='fontweigth400'> (if applicable)</span></li>
                          <li >Recent Passport Size Photographs</li>
                        </ul>

                      </td>


                    </tr>

                  </tbody>
                </table>

              </div>
            </div>

        </div >
      </section >


      <section className="container-fluid  p-0 pb-0 pt-0 pt-md-3" id="whyus">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='fh3MDS pt-2'>Why Choose Us for MDS Admission in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>The NEET-MDS counselling process can be daunting and overwhelming at times. A seemingly small move can affect the outcome of the counselling process. Efficient guidance can help take the load off of you and that is what we provide.
            </p>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>With 30 Years of expertise, we can help you acquire a seat in one of the top MDS colleges in Karnataka. We not only provide information about counselling registration, choice filling, and MDS course fees but also help you through the entire counselling process. So how about you leave the logistics to us and focus on acing the NEET MDS exam?
            </p>


            </div>
            <div className="col-lg-12 text-center pt-0 pt-md-3 pb-3 ">

              <img src={MDSSteps} className='m-auto mdsimage d-md-block d-none ' alt='MDS Steps'></img>


            </div>



            <div className="col-12 col-md-4 d-md-none d-block mb-3">
              <div className="card shadow-sm Card-width" style={{ textAlign: "center", borderRadius: "15px" ,backgroundColor: "white" }}>
                <img src={step1} className="card-img-top mdsimg4" alt="step1" style={{ borderRadius: "15px" }} />
              </div>
            </div>
            <div className="col-12 col-md-4 d-md-none d-block mb-3">
              <div className="card shadow-sm Card-width" style={{ textAlign: "center", borderRadius: "15px" , backgroundColor: "white" }}>
                <img src={step2} className="card-img-top mdsimg4" alt="step2" style={{ borderRadius: "15px" }} />
              </div>
            </div>
            <div className="col-12 col-md-4 d-md-none d-block mb-3">
              <div className="card shadow-sm Card-width" style={{ textAlign: "center", borderRadius: "15px" , backgroundColor: "white" }}>
                <img src={step3} className="card-img-top mdsimg4" alt="step3" style={{ borderRadius: "15px" }} />
              </div>
            </div>
            <div className="col-12 col-md-4 d-md-none d-block mb-3">
              <div className="card shadow-sm Card-width" style={{ textAlign: "center", borderRadius: "15px" , backgroundColor: "white" }}>
                <img src={step4} className="card-img-top mdsimg4" alt="step4" style={{ borderRadius: "15px" }} />
              </div>
            </div>



          </div>




        </div >

      </section >

      <section className="container-fluid  p-0 pb-0 pt-4" id="topdental"  >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='fh3MDS-dental'>Top Dental Colleges in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>Education shapes one’s career. Hence, it is important to choose the right college. Students need to gain apt knowledge and skills to have a flourishing career in the field of Dentistry. Therefore, pursuing their education from one of the best colleges would go a long way.
            </p></div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>Aspirants who wish to enhance their knowledge in the field of dentistry can study from one of the top Dental colleges in Karnataka.

            </p></div>
          </div>
          <div className="col-lg-12 text-center">
            <div className="row row-cols-1 row-cols-md-4 g-4 bdslandgingcolleges bdscollegeimage mds">


              <div className="col">
                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Manipal} alt="Manipal College of Dental Sciences (MCODS)" />
                  <div className="card-body">
                    <h5 className="card-title">Manipal College of Dental Sciences (MCODS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Manipal & Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>

              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Shetty} alt="A B Shetty Memorial Institute of Dental Sciences (ABSMIDS)" />
                  <div className="card-body">
                    <h5 className="card-title">A B Shetty Memorial Institute of Dental Sciences (ABSMIDS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={JSS} alt="JSS Dental College & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title">JSS Dental College & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mysore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Ramaiah} alt="Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS)" />
                  <div className="card-body">
                    <h5 className="card-title">Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={SDM} alt="SDM College of Dental Sciences & Hospital " />
                  <div className="card-body">
                    <h5 className="card-title">SDM College of Dental Sciences & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Dharwad
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Yenepoya} alt="Yenepoya Dental College" />
                  <div className="card-body">
                    <h5 className="card-title">Yenepoya Dental College</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={KLE} alt="KLE Vishwanath Katti Institute of Dental Sciences & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title">KLE Vishwanath Katti Institute of Dental Sciences
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Belgaum
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Bapuji} alt="Bapuji Dental College & Hospital" />
                  <div className="card-body">
                    <h5 className="card-title">Bapuji Dental College & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Davangere
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100 justify-content-center align-items-center" >
                  <img className="card-img-top imgcontain" src={Oxford} alt="The Oxford Dental College" />
                  <div className="card-body">
                    <h5 className="card-title">The Oxford Dental College



                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>

              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Pandu} alt="D A Pandu Memorial RV Dental College & Hospital " />
                  <div className="card-body">
                    <h5 className="card-title">D A Pandu Memorial RV Dental College & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker redi " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>

              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Dayananda} alt="Dayananda Sagar College of Dental Sciences (DSCDS)" />
                  <div className="card-body">
                    <h5 className="card-title">Dayananda Sagar College of Dental Sciences (DSCDS)



                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>


              <div className="col">

                <div className="card h-100 justify-content-center align-items-center">
                  <img className="card-img-top imgcontain" src={Vydehi} alt="Vydehi Institute of Dental Sciences & Research Centre (VIDSRC)" />
                  <div className="card-body">
                    <h5 className="card-title">Vydehi Institute of Dental Sciences & Research Centre (VIDSRC)

                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnmdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>











            </div>
          </div>
          <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 20 }} id="FAQs">
            & Many More!</div>


        </div>
      </section >

      {/* <section className='pb-0 pt-0 px-0'>
        <div className="container-fluid pt-0 pb-5 px-0 border-0 bd-example  border-0 bd-example-row bgfaq">
          <div className="container row justify-content-center g-0 ">
            <div className="col-lg-12 text-center pt-4 pb-3">
              <h3 className='fh3MDS'>FAQs</h3>
            </div>
            <div className="col-lg-12  row text-center card-sri2 pb-3 g-3">


              <div className="col-12 col-md-4">
                <div className="card">
                  <h3 className='h1sri pb-1'><span className='font23'>Q.</span> What is the procedure for MDS admission in Bangalore?</h3>
                  <p className='p-2'>
                    Students need to qualify NEET-MDS and participate in the NEET-MDS Counselling conducted by MCC or KEA to acquire a seat in top MDS Colleges in Bangalore.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <h3 className='h1sri pb-1'><span className='font23'>Q.</span> What is the MDS course duration and fees?</h3>
                  <p className='p-2'>
                    Master of Dental Surgery (MDS) is a 3-year postgraduate program in the field of Dentistry. Some of the top colleges in Karnataka have MDS course fees that are budget-friendly. Interested students can contact us for further details.


                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <h3 className='h1sri pb-1'><span className='font23'>Q.</span> What are the MDS Specializations offered in private Dental colleges in Bangalore?
                  </h3>
                  <p className='p-2'>
                    Some of the MDS Specializations provided by private dental colleges in Bangalore are MDS Prosthodontics and Crown & Bridge, MDS Oral & Maxillofacial Surgery, MDS Orthodontics & Dentofacial Orthopedics, MDS Conservative Dentistry and Endodontics and many more.

                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <h3 className='h1sri pb-1'><span className='font23'>Q.</span> Which are the best MDS Colleges in Bangalore?
                  </h3>
                  <p className='p-2' >
                    Some of the best MDS Colleges in Bangalore are Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS), the Oxford Dental College, Vydehi Institute of Dental Sciences & Research Centre (VIDSRC), Dayananda Sagar College of Dental Sciences (DSCDS), and more.



                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <h3 className='h1sri pb-1'><span className='font23'>Q.</span> What are some Dental Colleges in Mangalore?
                  </h3>
                  <p className='p-2'>
                    Some of the top Dental Colleges in Mangalore are Manipal College of Dental Sciences (MCODS), A B Shetty Memorial Institute of Dental Sciences (ABSMIDS), Yenepoya Dental College, A. J. Institute of Dental Sciences (AJIDS) and more.



                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <h3 className='h1sri pb-1'><span className='font23'>Q.</span> What is the MDS fee structure in private colleges?
                  </h3>
                  <p className='p-2'>
                    Some top private Dental colleges in Bangalore fee structure for MDS is affordable. Contact us for more details regarding MDS course fees in Bangalore and MDS fees in Private Colleges in Karnataka.





                  </p>
                </div>
              </div>


            </div>



          </div>
        </div>

      </section> */}

      <section className='pb-0 pt-0 px-0'>
        <div className="faq-section p-3 bg-gray-2" >
          <div className="container ">
            <div className="faq-header ">
              <h2 className='fh3MDS-dental text-center'>
                FAQs
              </h2>
            </div>
            <div className="faq-outer ">
              <div className="faq-img Ambfaq-img ">
                <img src={Faqimg} alt="FAQs " />
              </div>
              <div
                className="faq-sec Ambfaq-sec "
                style={{
                  background:
                    "linear-gradient(90deg,transparent, #ebebfb, transparent)"
                }}
              >
                <div className="">
                  <div className="Accordions ">
                    <div className="Accordion_item ">
                      <div className="title_tab ">
                        <h3 className="title ">
                          What is the procedure for MDS admission in Bangalore?
                          <span className="icon ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg "
                              width={16}
                              height={16}
                              fill="currentColor "
                              className="bi bi-chevron-down "
                              viewBox="0 0 16 16 "
                            >
                              <path
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                              />
                            </svg>
                          </span>
                        </h3>
                      </div>
                      <div className="inner_content ">
                        <p>
                          Students need to qualify NEET-MDS and participate in the NEET-MDS counselling process conducted by MCC or KEA to acquire a seat in top MDS Colleges in Bangalore.
                        </p>
                      </div>
                    </div>
                    <div className="Accordion_item ">
                      <div className="title_tab ">
                        <h3 className="title ">
                          What is the MDS course duration and fees?

                          <span className="icon ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg "
                              width={16}
                              height={16}
                              fill="currentColor "
                              className="bi bi-chevron-down "
                              viewBox="0 0 16 16 "
                            >
                              <path
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                              />
                            </svg>
                          </span>
                        </h3>
                      </div>
                      <div className="inner_content ">
                        <p>
                          Master of Dental Surgery (MDS) is a 3-year postgraduate program in the field of Dentistry. The MDS course fees vary based on many factors such as location, infrastructure, the quota opted for, etc. Interested students can contact us for further details.

                        </p>
                      </div>
                    </div>
                    <div className="Accordion_item ">
                      <div className="title_tab ">
                        <h3 className="title ">
                          What are the MDS Specialisations offered at private Dental colleges in Bangalore?

                          <span className="icon ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg "
                              width={16}
                              height={16}
                              fill="currentColor "
                              className="bi bi-chevron-down "
                              viewBox="0 0 16 16 "
                            >
                              <path
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                              />
                            </svg>
                          </span>
                        </h3>
                      </div>
                      <div className="inner_content ">
                        <p>
                          Some of the MDS specialisations provided by private dental colleges in Bangalore are MDS Prosthodontics and Crown & Bridge, MDS Oral & Maxillofacial Surgery, MDS Orthodontics & Dentofacial Orthopedics, MDS Conservative Dentistry and Endodontics and many more.

                        </p>
                      </div>
                    </div>
                    <div className="Accordion_item ">
                      <div className="title_tab ">
                        <h3 className="title ">
                          Which are the best MDS Colleges in Bangalore?
                          <span className="icon ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg "
                              width={16}
                              height={16}
                              fill="currentColor "
                              className="bi bi-chevron-down "
                              viewBox="0 0 16 16 "
                            >
                              <path
                                // fillRule="evenodd "
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                              />
                            </svg>
                          </span>
                        </h3>
                      </div>
                      <div className="inner_content ">
                        <p>
                          Some of the best MDS Colleges in Bangalore are Faculty of Dental Sciences, Ramaiah University of Applied Sciences (RUAS), The Oxford Dental College, Vydehi Institute of Dental Sciences & Research Centre (VIDSRC), Dayananda Sagar College of Dental Sciences (DSCDS), and more.
                        </p>
                      </div>
                    </div>
                    <div className="Accordion_item ">
                      <div className="title_tab ">
                        <h3 className="title ">
                          What are some of the top Dental Colleges in Mangalore?

                          <span className="icon ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg "
                              width={16}
                              height={16}
                              fill="currentColor "
                              className="bi bi-chevron-down "
                              viewBox="0 0 16 16 "
                            >
                              <path
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                              />
                            </svg>
                          </span>
                        </h3>
                      </div>
                      <div className="inner_content ">
                        <p>
                          Some of the top Dental Colleges in Mangalore are Manipal College of Dental Sciences (MCODS), A B Shetty Memorial Institute of Dental Sciences (ABSMIDS), Yenepoya Dental College, A. J. Institute of Dental Sciences (AJIDS) and more.

                        </p>
                      </div>
                    </div>
                    <div className="Accordion_item ">
                      <div className="title_tab ">
                        <h3 className="title ">
                          What is the MDS fee structure in private colleges?
                          <span className="icon ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg "
                              width={16}
                              height={16}
                              fill="currentColor "
                              className="bi bi-chevron-down "
                              viewBox="0 0 16 16 "
                            >
                              <path
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                              />
                            </svg>
                          </span>
                        </h3>
                      </div>
                      <div className="inner_content ">
                        <p>
                          For private Dental colleges in Bangalore, fee structure for MDS would vary based on certain factors. Contact us for more details regarding MDS course fees in Bangalore and MDS fees in Private Colleges in Karnataka.

                        </p>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="container-fluid d-flex align-items-center pt-0 pb-0 px-0 " id="Contact">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='dd '><h2 className='bgblack p-3'>Master Your Dentistry Skills from Top Dental Colleges in Karnataka.</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5 font-white23 btnmdscolour " onClick={handleShow}>
              Apply Now!

            </Button>
          </div>
        </div>


      </section>

      <footer className='pb-md-1 pb-5 bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <img src={Defaultlogo} className="imgfooter" alt="bangalorestudy " />
              </div>


            </div>

            <div className="col-md-6 footer-left text-center pe-5 " >
              <p className="font-white23 pe-5">Social Connect</p>
              <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5'>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa-brands fa-x-twitter"></i>&nbsp; &nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/ "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                </a>
              </p>

            </div>

          </div>




          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100 px-5 d-flex justify-content-end" >
              <div><p className=" px-5 font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 15, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 15, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 15 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696(Toll
                    Free)
                  </a>
                </div>
                <p className='pb-3 pb-md-0'>
                  Dubai :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontWeight: 400 , fontSize: 15, }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <br className='d-block' />
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontWeight: 400, paddingLeft: '70px' , fontSize: 15, }}
                    className='icpnphone icpadding'
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971504955123{" "}
                  </a>
                </p>



              </div>
            </div>
          </div>

          <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
            {" "}
            © 2025 Bangalorestudy.com. All Rights Reserved.
          </p>

          </div>


        </div>
        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left pt-5 col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
              <img src="preprod/nursing_admission/assets/images/footer-bg.png " className="imgfooter" alt="bangalorestudy " />
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex">
            <div className="app-unit col-md-7  col-md-5 ">
              <div className="store-wrapper-mds">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/ "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div>
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 15, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 15, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 15 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696(Toll Free)
                  </a>
                </div>
                <p className='pb-3 pb-md-0'>
                  Dubai :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontWeight: 400 , fontSize: 15, }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <br className='d-block d-md-none' />
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontWeight: 400, paddingLeft: '70px' , fontSize: 15, }}
                    className='icpnphone icpadding'
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971504955123{" "}
                  </a>
                </p>
              </div>
            </div>
            <p className="pb-5">
              {" "}
              © 2025 Bangalorestudy.com. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      {/* <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        <i className="bi bi-whatsapp my-float " />
      </a> */}


      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 8,
          right: 24,
          // backgroundColor: "#25d366",
          // color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          // boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
        <img src={whtsp} alt='whtsp logo' style={{ width: "66px" }}></img>
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>{textmodal}</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default MDS;