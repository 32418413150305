import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/synslandingpage/svyasa.png';
import logo2 from 'src/assets/synslandingpage/logo2.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ManagementCourseBG from 'src/assets/synslandingpage/newbanner.png';
import Scope1 from 'src/assets/synslandingpage/Scope-1.png';
import Scope2 from 'src/assets/synslandingpage/Scope-2.png';
import Scope3 from 'src/assets/synslandingpage/Scope-3.png';
import Scope4 from 'src/assets/synslandingpage/Scope-4.png';
import Scope5 from 'src/assets/synslandingpage/Scope-5.png';
import Scope6 from 'src/assets/synslandingpage/Scope-6.png';
import Scope7 from 'src/assets/synslandingpage/Scope-7.png';
import Scope8 from 'src/assets/synslandingpage/Scope-8.png';
import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';



import contactbanner from 'src/assets/synslandingpage/banner1.png';

import AICTE from 'src/assets/synslandingpage/AICTE.png';
import UGC from 'src/assets/Svyasaallied/UGC.png';
import UGC1 from 'src/assets/synslandingpage/KSURF-new.png';
import NAAC from 'src/assets/synslandingpage/NAAC A+.png';
import Ayush from 'src/assets/synslandingpage/Ayush.png';
import KSURF from 'src/assets/Svyasaallied/KSURF.png';


import "./styles.css";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const BscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();



  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'S-VYASA BNYS Admission 2025-26');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [expanded, setExpanded] = useState(false);

  const navigate = (id, offset = 0) => {
    const elementToView = document.getElementById(id);
    if (elementToView) {
      const offsetTop = elementToView.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth"
      });
      setExpanded(false); // Collapse the navbar after clicking any link
    } else {
      console.error("Element not found:", id);
    }
  };


  return (


    <>
      <Helmet>
        <title>BNYS Course Admission at S-VYASA University, Bangalore</title>
        <meta name="description"
          content="Get Started with the Direct BNYS Admission 2024-25 at the S-VYASA University, Bangalore. Know all about the BNYS Course: Details, Full-form, Course Duration, Eligibility Criteria, Course Fees Structure, Scope, & more. Apply Now!" />
        <link rel="canonical" href="https://bangalorestudy.com/svyasa-bnys-admission" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "S-VYASA BNYS Admission 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "What is the BNYS full form?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The BNYS full form is Bachelor of Naturopathy and Yogic Sciences. It is an undergraduate course in the field of AYUSH."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the BNYS Eligibility Criteria?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The BNYS Eligibility Criteria is to procure a minimum aggregate of 50% marks in 10+2/ 2nd PUC with Physics, Chemistry, and Biology Subjects."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the BNYS course duration?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The BNYS course duration is 5.5 years which includes 4.5 years of academic study followed by 1 year of internship."
                  }
                }, {
                  "@type": "Question",
                  "name": "Is BNYS Admission without NEET possible?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "There is no entrance exam for the BNYS course. Hence, candidates meeting the prescribed eligibility criteria can get direct BNYS admission without NEET."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the S-VYASA, Bangalore, address?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The Swami Vivekananda Yoga Anusandhana Samsthana, Bangalore, is located in Prashanti Kutiram, Vivekananda Road, Kalluballu Post, Jigani, Anekal, Bengaluru, Karnataka - 560105."
                  }
                }, {
                  "@type": "Question",
                  "name": "How are the S-VYASA University reviews?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The best-in-class amenities, experienced faculty and calm learning environment have contributed to consistently aid the S-VYASA University reviews in a progressive way."
                  }
                }, {
                  "@type": "Question",
                  "name": "How are the S-VYASA University hostels?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The S-VYASA University hostels are equipped with hospitable facilities that provide a comfortable stay and learning environment for students enrolled in its residential programs."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the BNYS course fees structure at S-VYASA University, Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The S-VYASA University BNYS course fees come in affordable packages and can be paid term-wise. Get in touch with us to know the updated BNYS course fee structure at S-VYASA Yoga University, Bangalore, for 2024-25."
                  }
                }, {
                  "@type": "Question",
                  "name": "How are the S-VYASA University Placements?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The evidence based teaching methodologies and internships as part of the course module imparts the necessary knowledge and skills to have a successful career in the field. Hence, students of S-VYASA Yoga University, Bangalore, find excellent work opportunities right after graduation."
                  }
                }, {
                  "@type": "Question",
                  "name": "Is S-VYASA one of the Top BNYS Colleges in Karnataka?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Recognized by UGC with the ‘Deemed-to-be University’ status in 2002, S-VYASA Yoga University, Bangalore, is considered to be one of the Top BNYS Colleges in Karnataka as it makes sure to provide amicable facilities that are required for quality education and training in Yoga in its serene campus."
                  }
                }

                ]
            }
          )}
        </script>

      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec left138 top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9996668116">
              <span> +91 9996668116 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70 left290" style={{ left: "-292px" }}>
          <div className="mail-sec cont-sec">
            <a href="mailto:svyasa.admissions01@gmail.com">
              <span>svyasa.admissions01@gmail.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9996668116">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:svyasa.admissions01@gmail.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      {/* <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="BNYS Course in S-VYASA Deemed-to-be University"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'about'))}>About S-VYASA</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'BNYS'))}>BNYS @ S-VYASA</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Scope'))}>Scope of BNYS</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'admission'))}>Admissions</Nav.Link>
              =
                <Nav.Link href="" onClick={(e => navigate(e, 'FAQs'))}>FAQs</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Contactus'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header> */}

      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" className="bg-body-tertiary" variant="light" expanded={expanded}>
          <Container fluid >
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="BNYS Course Admission at S-VYASA University, Bangalore"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse className='justify-content-end' id="responsive-navbar-nav ">
              <Nav className="ml-auto">
                <Nav.Link onClick={() => { navigate('home'); }}>Home</Nav.Link>
                <Nav.Link onClick={() => { navigate('about'); }}>About S-VYASA</Nav.Link>
                <Nav.Link onClick={() => { navigate('BNYS'); }}>BNYS @ S-VYASA</Nav.Link>
                <Nav.Link onClick={() => { navigate('Scope'); }}>Scope of BNYS</Nav.Link>
                <Nav.Link onClick={() => { navigate('admission', 80); }}>Admissions</Nav.Link>
                {/* <Nav.Link onClick={() => { navigate('whyus', 80); }}>Why Us</Nav.Link> */}
                <Nav.Link onClick={() => { navigate('FAQs'); }}>FAQs</Nav.Link>
                <Nav.Link onClick={() => { navigate('Contactus'); }}>Contact Us</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>


      <section className="d-flex align-items-center pb-0" id="home" style={{ paddingTop: 95 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid synsbgimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 pt-3"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center px-0 px-md-3">
                <div className='bgblacksyn slide-in'>
                  <p className='firstp '>Swami Vivekananda Yoga Anusandhana Samsthana</p>
                  <h1 className='text-center h1BDS h1bnys  pt-3 pt-md-0'>S-VYASA University, Bangalore

                  </h1>

                  <p className='svyasa-allied color-bnys pb-4 pt-4 blinking-mount-text-decrease  '>BNYS Admissions 2024-25 Open!</p>


                  <p className='text-center p pt-3 pb-3 m-0 font23 ' >Learn to Treat Lifestyle Diseases in Style! <br className='d-none d-md-block' />
                    Study Bachelor of Naturopathy and Yogic Sciences (BNYS) at<br className='d-none d-md-block' /> S-VYASA, World’s First Yoga University.
                  </p>

                  <Button variant="primary" className="btn btn-success mb-4 btnbds" onClick={handleShow}>
                    Download Brochure
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 px-0">

                <div className="form-box p-md-4 slide-in2">
                  <div>
                    <p className='text-center p m-0 pb-4 font23 d-md-none' style={{ color: 'black' }} >Admissions Open!
                    </p>
                    <p className=' d-none d-md-block text-center p m-0 pb-4 font23' >Admissions Open!
                    </p>
                    <h3
                      className="f700 pb-3 pt-4 pt-md-0"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Fill up the S-VYASA University BNYS Application Form 2024-25.


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    {/* <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div> */}

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andaman & Nicobar">Andaman & Nicobar</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                        <option value="Daman & Diu">Daman & Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir">Jammu & Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" >
                      <button className="btn btn-success submit btnbdscolour" name="submit" defaultValue="Submit" type="submit">Submit</button>

                    </div>
                  </form>
                  <span id="about" style={{ display: "hidden" }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-0 pt-4" style={{ color: "black" }}  >
        <div className="container-fluid " >
          <div className='row px-0 px-md-3 justify-content-between'>
            <div className='col-12 sayushh2why h2 ' >
              <p style={{ color: "black" }}>Swami Vivekananda Yoga Anusandhana Samsthana</p>
              About S-VYASA
              <span className='d-block fontw400 pb-2'>Deemed-to-be University, Bangalore
              </span>
              <span className='d-block'>“Be and Make!”
              </span>

            </div>
            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>

              <img src={AICTE} className='swamiimg4' alt='AICTE'></img>
              <h3 className='swamibule' >Approved by AICTE,</h3>
              <p className='pb-4 pb-md-0'>Ministry of HRD, GoI</p>

            </div></div>
            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>

              <img src={UGC} className='swamiimg4' alt='UGC '></img>
              <h3 className='swamibule pb-4' >UGC Category I University</h3>

            </div></div>

            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>
              <img src={NAAC} className='swamiimg4' alt='NAAC'></img>
              <h3 className='swamibule pb-4 pb-md-0' >Accredited by NAAC</h3>

            </div></div>

            <div className="col-12 col-md-2">     <div style={{ textAlign: "center" }}>
              <img src={Ayush} className='swamiimg4' alt='AYUSH'></img>

              <h3 className='swamibule'>Accorded ‘Centre of Excellence’ in Yoga by Ministry of AYUSH, GoI</h3>

            </div></div>
            <div className="col-12 col-md-2 d-none d-md-block">     <div style={{ textAlign: "center" }}>
              <img src={KSURF} className='swamiimg4' alt='Scholarship'></img>
              <h3 className='swamibule'>4 Star Specialist University</h3>
            </div>
            </div>

            <div className="col-12 col-md-2 d-block d-md-none">     <div style={{ textAlign: "center" }}>
              <img src={UGC1} className='swamiimg4' alt='Scholarship'></img>
              <h3 className='swamibule pt-2'>4 Star Specialist University</h3>
            </div>
            </div>





          </div>
        </div>
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-12 text-md-center px-0 ">
              <p className='textmbbs  pt-3'>The Swami Vivekananda Yoga Anusandhana Samsthana (S-VYASA) is the first institution  in the <br className='d-none d-md-block' />  world to roll out dedicated UG, PG and PhD courses in Yogic Sciences. S-VYASA was <br className='d-none d-md-block' /> recognized by the University Grants Commission (UGC) with the ‘Deemed-to-be University’  <br className='d-none d-md-block' /> status in the year 2002. Ever since its establishment, the university has strived to make Yoga a <br className='d-none d-md-block' />  socially relevant Science.</p>
              <p className='textmbbs pt-3 ' >Mission of S-VYASA University</p>
            </div>
            <div className="col-lg-12  " >

              <h3 className='swamibule pt-4 pb-4 text-center'  >To Combine the Best of the <br /><span id="BNYS" style={{ display: "hidden" }}></span></h3>

              <div className="row justify-content-center align-items-center bodrdertext">
                <div className="col-4 text-center backgroundcard1 ">
                  <h2 className='swamibule'>East</h2>
                  <p className='textsyns'>Yoga and Spiritual Lore</p>

                </div>
                <div className="col-4 text-center ">
                  <p className='textsyns'>with that of the</p>

                </div>
                <div className="col-4 text-center  backgroundcard1" >
                  <h2 className='swamibule' >West</h2>
                  <p className='textsyns' >Modern Scientific Research</p>

                </div>

              </div>


            </div>
          </div>
        </div>



      </section >


      <section className="container-fluid  pb-0 pt-4"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-12  pb-md-3">
              <h3 className='fh3BDS bnys-font text-center'>BNYS @ S-VYASA
              </h3>
            </div>
            <div className="col-lg-12 text-md-center pt-3 pt-md-0 pb-3"><p className='textmbbs pb-3'>The Bachelor of Naturopathy and Yogic Sciences (BNYS) course offers an excellent opportunity<br className='d-none d-md-block' /> for those who wish to become a Doctor without having to go through the strenuous processes of <br className='d-none d-md-block' />NEET Exam and NEET Counselling. Yes, BNYS Admission without NEET is possible.</p>

              <div className='d-none d-md-block'>
                <div className='bodrdertext  backgroundcard py-3 divfont d-flex justify-content-around align-items-center px-3 px-md-0' style={{ maxWidth: "650px", margin: "auto" }}>
                  <div>Become a Doctor without NEET!</div>
                  <Button variant="primary" className="btn btn-success submit btnsyns" onClick={handleShow}>
                    Enquire Now

                  </Button>

                </div>

              </div>

              <div className='divpadding'>
                <div className='bodrdertext backgroundcard py-3 divfont  align-items-center px-0 px-md-0 d-md-none d-block divpadding' style={{ maxWidth: "650px", margin: "auto" }}>
                  <div className='text-center' style={{ fontSize: '19px' }}>Become a Doctor without NEET!</div>

                  <div className="text-center pt-2 pb-2">
                    <Button variant="primary" className="btn btn-success submit btnbyvs" onClick={handleShow}>
                      Enquire Now

                    </Button>
                  </div>

                </div>
              </div>
              <p className='textmbbs pt-3'>Before getting started with the BNYS Admission Process, let’s get familiar with the BNYS course details.</p>
            </div>



            <div className="col-lg-12 px-0 px-md-5 ">
              <div className=" swamicustomborder1">
                <table className="table table-bordered">
                  <tbody>

                    <tr>
                      <th scope="row">BNYS Full-form</th>
                      <td colSpan={3}>Bachelor of Naturopathy and Yogic Sciences</td>

                    </tr>
                    <tr>
                      <th scope="row">Course Level</th>
                      <td colSpan={3}>Undergraduate (UG)</td>

                    </tr>
                    <tr>
                      <th scope="row">BNYS Course Duration</th>
                      <td colSpan={3}>5.5 Years
                        <p>(4.5 Year Academic Study + 1 Year Internship)</p></td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}>None</td>

                    </tr>
                    <tr>
                      <th scope="row">BNYS Eligibility Criteria</th>
                      <td colSpan={3} className='d-flex justify-content-center'>
                        <ul className='ps-4' style={{ listStyle: "none" }}>
                          <li>50% Aggregate Marks in 10+2/ 2nd PUC with</li>
                          <li>Physics, Chemistry and Biology Subjects</li>
                        </ul>
                      </td>

                    </tr>
                    <tr>
                      <th scope="row">Mode of Learning @ S-VYASA</th>
                      <td colSpan={3}>Residential</td>

                    </tr>
                  </tbody>
                </table>


              </div>
            </div>

          </div>

        </div >
      </section >

      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='fh3BDS bnys-font'>Why BNYS @ S-VYASA</h3>
            </div>

            <div className="col-lg-12 text-md-center pt-3 pb-3"><p className='textmbbs'>Being the World’s First Yoga University, the campus of S-VYASA University, Bangalore, called <br className='d-none d-md-block' /> ‘Prashanti Kutiram’, meaning ‘Abode of Peace’, is designed with utmost care and attention to <br className='d-none d-md-block' /> facilitate quality education and training in Yoga. Here are a few reasons that can act as a focal <br className='d-none d-md-block' /> point to determine why one must study BNYS at S-VYASA Yoga University, Bangalore.
            </p></div>



            <div className="choose-section customboxes  ">
              <div className="container">

                <div
                  className="choose-box-outer customboxes why_choose_us"
                  style={{ gridTemplateColumns: "auto auto", gap: "1rem 3rem" }}
                >
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Academic Counsellors with years of expertise"
                      />
                    </span>
                    <h4>Only BNYS Offering Institution with NAAC ‘A+’</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Comprehensive Admission Guidance"
                      />
                    </span>
                    <h4>Serene Campus Conducive for Studies in Yoga</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Helping hand in the College application process"
                      />
                    </span>
                    <h4>Eminent Faculties & Mentors</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Helping hand in the College application process"
                      />
                    </span>
                    <h4>Interactive & Evidence-Based Teaching Methodologies</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Assistance for Availing Educational Loans and Scholarships"
                      />
                    </span>
                    <h4>Well-equipped Laboratories</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="College visit before Admission"
                      />
                    </span>
                    <h4>Dedicated Library, Digital Library, Research Laboratories</h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Comprehensive website for direct interaction"
                      />
                    </span>
                    <h4>
                      Clinical Exposure at Arogyadhama, a 250-Bedded Integrative Medicine Teaching Hospital
                    </h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Comprehensive website for direct interaction"
                      />
                    </span>
                    <h4>
                      Visits & Internships at various Leading Medical Institutions as Part of the Curriculum
                      <span style={{ fontWeight: 400 }}>(NIMHANS, Narayana Health, etc.)</span>

                    </h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Comprehensive website for direct interaction"
                      />
                    </span>
                    <h4>
                      Seminars & Conferences to Understand Recent Advances

                    </h4>
                  </div>
                  <div className="d-flex choose-box-inner">
                    <span className="choose-icon">
                      <img
                        src="preprod/nursing_admission/assets/images/graduation.png"
                        alt="Comprehensive website for direct interaction"
                      />
                    </span>
                    <h4>
                      ICMR Accredited & Sponsored ‘Centre for Advanced Research’ (CAR) in Yoga

                    </h4>
                  </div>





                </div>
              </div>
            </div>

            <div className="col-lg-12 text-md-center pt-3 pb-3"><p className='textmbbs'>With such features and facilities, the S-VYASA University, Bangalore, has consistently ranked <br className='d-none d-md-block' />among the Top BNYS Colleges in Karnataka and the Best Naturopathy Colleges in Bangalore.
            </p></div>
            <div className='bodrdertext backgroundcard py-3 divfont' style={{ textAlign: "center", color: "black" }}> <span id="Scope" style={{ display: "hidden" }}></span>Experience the ‘Indian Gurukula’ Way of Education Combined with a Modern Approach.
            </div>

          </div>



        </div>
      </section >

      <section className="container-fluid  pb-0 pt-4" >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center swamiscope ">
            <div className="col-lg-12 ">
              <h3 className='fh3BDS bnys-font text-center'>Scope of BNYS
              </h3>
            </div>
            <div className="col-lg-12 text-md-center  pt-3 pb-3"><p className='textmbbs'>With the revival of the traditional systems of medicines across the world, a career in Naturopathy and <br className='d-none d-md-block' />Yogic Sciences in this day and age is one of the best choices to consider. With its holistic<br className='d-none d-md-block' /> approach towards health and vitality, this field of medicine offers incomparable long-term health <br className='d-none d-md-block' />benefits, thus ensuring a fulfilling career.
            </p>

              <p className='textmbbs pt-3 pt-md-2'>Listed below are a few career opportunities from a wide range of them for a BNYS graduate.</p></div>



            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope1} alt="Scope1" />


              <p>AYUSH Physician in Govt. Sector     <span>(Central & State Schemes, Hospitals, etc.)</span></p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope2} alt="scope2" />


              <p>Clinical Practice, Consultant in Leading Medical Institutions</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope3} alt="scope3" />


              <p>Clinical Practice in AYUSH Clinics & Hospitals</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope4} alt="scope4" />


              <p> Own Private Practice</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope5} alt="scope5" />


              <p>Faculty in Academic & Research Organizations</p>
            </div>
            <div className="col-lg-6 d-flex  mt-2 mt-md-0 align-items-center scope">

              <img src={Scope6} alt="scope6" />


              <p id="bdsaddmission">Therapists & Lifestyle Consultants in the Wellness Industry<span>(MNCs, Cruises, Star Hotels, Spas, etc.)</span> </p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope7} alt="scope6" />


              <p id="bdsaddmission">Central Govt. Deputations</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope8} alt="scope6" />


              <p id="bdsaddmission">Lucrative Work Opportunities Abroad
              </p>
            </div>

            <div className="col-lg-12 text-md-center  pt-3" ><p className='textmbbs'>Studying from a premier institution is crucial to having a successful career and puts you ahead <br className='d-none d-md-block' />of your peers. The highly researched course module which includes adequate practical training<br className='d-none d-md-block' /> equips each student to perform exceptionally well in the S-VYASA University Placements and <br className='d-none d-md-block' />opens a world full of opportunities for them.
            </p>

            </div>

          </div>

        </div >
      </section >

      <section className="container-fluid  p-0 pb-0 pt-3" id="admission">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3 pb-3">
              <h3 className='fh3BDS bnys-font'>BNYS Admission 2024-25</h3>
            </div>


            <div className=" col-12 col-md-4 p-4 p-md-0 text-center   backgroundcard  divfont d-flex justify-content-center align-items-center">
              <h3 className='h3syns p-1'> S-VYASA BNYS Admissions</h3>

            </div>
            <div className=" col-12 col-md-8 text-center pt-3 pb-3 bggreay" style={{ color: "black" }}>
              <p className='textmbbs'>BNYS admissions at S-VYASA Yoga University, Bangalore, happens<br className='d-none d-md-block' /> every year.<br />
              </p>
              <p className='textmbbs'>Get in touch with us to know about  the documents required for direct BNYS<br className='d-none d-md-block' /> admissions.<br />
              </p>
              <p className='textmbbs'>BNYS Admission 2024-25 at S-VYASA University, Bangalore, is Now<br className='d-none d-md-block' /> Open.<br />
              </p>



              <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                Apply Now
              </Button>



            </div>
            <div className="col-12 col-md-4    p-4 p-md-0 text-center   backgroundcard  divfont d-flex justify-content-center align-items-center mt-4 ">
              <h3 className='h3syns p-1'> S-VYASA BNYS Scholarships</h3>

            </div>
            <div className="col-12 col-md-8 text-center pt-3 pb-3 bggreay mt-0 mt-md-4">
              <h3 className='font23' style={{ color: "black" }}>The Swami Vivekananda Yoga Anusandhana Samsthana, Bangalore, supports the needy and meritorious students financially through the following means:
              </h3>
              <ul className='ps-3 ps-md-5 text-start m-auto pt-3' style={{ lineHeight: "2", fontSize: 18, color: "black" }}>
                <li>Central & State Government Scholarships</li>
                <li>Scholarships from Non-Governmental Institutions</li>
                <li>Education Loans by Philanthropic Institutions</li>
                <li>Assistantship/ Part-Time Work Opportunities in the Campus</li>
              </ul>



              <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                Know More
              </Button>



            </div>
            <div className="col-12 col-md-4   p-4 p-md-0 text-center   backgroundcard  divfont d-flex justify-content-center align-items-center mt-4">
              <h3 className='h3syns p-1'>S-VYASA University <br /> BNYS Course Fees</h3>

            </div>
            <div className="col-12 col-md-8 text-center pt-3 pb-3 bggreay mt-0 mt-md-4">
              <p className='textmbbs'>S-VYASA University BNYS course fees come in affordable packages <br className='d-none d-md-block' /> with an intent to make education in Yoga accessible to all. </p>
              <br />
              <p className='textmbbs'>BNYS course fees in S-VYASA University, Bangalore, can be paid <br className='d-none d-md-block' />term-wise, i.e. in 2 terms a year, through DD/ Card/ Online (NEFT/ RTGS).</p>
              <br />
              <p className='textmbbs'>Get in touch with us to know the complete BNYS course fee structure at <br className='d-none d-md-block' />S-VYASA University, Bangalore, for 2024-25.
              </p>



              <Button variant="primary" id="FAQs" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                Enquire Now
              </Button>



            </div>


          </div>



        </div>
      </section >

      {/* <section className="container-fluid  p-0 pb-0 pt-3" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='fh3BDS bnys-font'>Why Us</h3>
            </div>

            <div className="col-lg-12 text-md-center pt-3 pb-3"><p className='textmbbs'>Considering the level of competition for college admissions in India, right<br className='d-none d-md-block' /> guidance at the right time can make all the difference. Securing admission in your desired course from a top college<br className='d-none d-md-block' /> might be a challenge and that’s why you need us.
            </p></div>
          </div>
          <div className="col-lg-12 text-center">
            <div className="swamicustomborder1 border-none mx-md-5 mx-0">
              <table className="table table-bordered documents">
                <tbody>
                  <tr className="d-flex flex-column  flex-md-row justify-content-center ">
                    <td className='pb-0 '>
                      <ul className='ps-3 ps-md-5 text-start ' style={{ lineHeight: "2", fontSize: 18 }}>
                        <li>25+ Years of Academic Counselling</li>
                        <li>Admission Assistance in India’s Top Colleges</li>
                        <li>Exclusive Web Portal for All Your Admission Needs</li>
                      </ul>

                    </td>
                    <td className='pt-0  pt-md-2'>
                      <ul className='ps-3 ps-md-5 text-start' style={{ lineHeight: "2", fontSize: 18 }} >
                        <li >Personalized Guidance Based on Individual Needs</li>
                        <li>Guided College Visit Before Admission</li>
                        <li >Assistance for Availing Scholarships & Educational Loans  <span id="FAQs" style={{ display: "hidden" }}></span></li>

                      </ul>

                    </td>


                  </tr>

                </tbody>
              </table>

            </div>

          </div>



        </div>
      </section > */}



      <div className="faq-section p-3 bg-gray-2"  >
        <div className="container ">
          <div className="faq-header ">
            <h2 className='fh3BDS bnys-font text-center'

            >
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img Ambfaq-img ">
              <img src="preprod/nursing_admission/assets/images/faq-img.png" alt="FAQs " />
            </div>
            <div
              className="faq-sec Ambfaq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BNYS full form?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The BNYS full form is Bachelor of Naturopathy and Yogic Sciences. It is an undergraduate course in the field of AYUSH.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BNYS Eligibility Criteria?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The BNYS Eligibility Criteria is to procure a minimum aggregate of 50% marks in 10+2/ 2nd PUC with Physics, Chemistry, and Biology Subjects.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BNYS course duration?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The  BNYS course duration is 5.5 years which includes 4.5 years of academic study followed by 1 year of internship.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Is BNYS Admission without NEET possible?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        There is no entrance exam for the BNYS course. Hence, candidates meeting the prescribed eligibility criteria can get direct BNYS admission without NEET.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the S-VYASA, Bangalore, address?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The Swami Vivekananda Yoga Anusandhana Samsthana, Bangalore, is located in Prashanti Kutiram, Vivekananda Road, Kalluballu Post, Jigani, Anekal, Bengaluru, Karnataka - 560105.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        How are the S-VYASA University reviews?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The best-in-class amenities, experienced faculty and calm learning environment have contributed to consistently aid the S-VYASA University reviews in a progressive way.
                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        How are the S-VYASA University hostels?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The S-VYASA University hostels are equipped with hospitable facilities that provide a comfortable stay and learning environment for students enrolled in its residential programs.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the BNYS course fees structure at S-VYASA University, Bangalore?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The S-VYASA University BNYS course fees come in affordable packages and can be paid term-wise. Get in touch with us to know the updated BNYS course fee structure at S-VYASA Yoga University, Bangalore, for 2024-25.
                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        How are the S-VYASA University Placements?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The evidence based teaching methodologies and internships as part of the course module imparts the necessary knowledge and skills to have a successful career in the field. Hence, students of S-VYASA Yoga University, Bangalore, find excellent work opportunities right after graduation.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title " >
                        Is S-VYASA one of the Top BNYS Colleges in Karnataka?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p >
                        Recognized by UGC with the ‘Deemed-to-be University’ status in 2002, S-VYASA Yoga University, Bangalore, is considered to be one of the Top BNYS Colleges in Karnataka as it makes sure to provide amicable facilities that are required for quality education and training in Yoga in its serene campus.



                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='ddsyns pe-md-5 me-md-5'><h2 className=' p-3' style={{
            background: "rgb(147 138 138 / 48%)",
            borderRadius: "11px"
          }}>Study BNYS at the World’s First Yoga University!</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5 font-white23 btnbdscolour " onClick={handleShow}>
              Apply Now

            </Button>
          </div>
        </div>


      </section>


      <footer className='bgfooter pb-5 p-0 pt-md-5 ' >
        <div className="footer-top p-0">
          <div className="container-fluid ">
            <div className="row " style={{ justifyContent: "space-between" }}>
              <div className="col-lg-6 col-md-6  footer-bottom-logo px-md-5 pt-4 pt-md-0 ">
                <div className=" mb-3 px-0 px-md-5" style={{ marginBottom: 20 }}>
                  <img src={logo2} className="imgfooter-bnys" alt="BNYS Course Admission at S-VYASA University, Bangalore " />
                </div>
              </div>


              <div className="col-lg-6 col-md-6 pb-5 pb-md-0  footer-newsletter text-white d-flex justify-content-md-end"

              >
                <div className='pt-4  pt-md-2 pe-md-5 text-white '>

                  <p>
                    <i className="bi bi-house-fill"></i><span > Prashanti Kutiram, Vivekananda Road, Kallubalu Post, </span>
                    <br className='d-none d-md-block' /> Jigani, Anekal, Bengaluru – 560105, INDIA
                  </p>
                  <p className='pt-2 pb-2 d-flex'>
                    <i className="bi bi-envelope-fill "></i>{" "}
                    <span className='fontsize-span mx-2 text-black footer-svyasa ' style={{ overflowWrap: 'break-word' }}>
                      <a href="mailto:svyasa.admissions01@gmail.com" style={{ color: "white", fontWeight: 400 }} className='footer-svyasa'>svyasa.admissions01@gmail.com</a>
                    </span>
                  </p>


                  {/* <p className='pt-2'> <i className="bi bi-telephone"></i> 9973733030
                  </p> */}
                  <div className="bs-phone footer-svyasa  " style={{ display: "contents" }}>
                    <a
                      href="tel:+91 9996668116 "
                      style={{ color: "white", fontWeight: 400, fontSize: '18px' }}
                      className='footer-svyasa'
                    >
                      <i className="bi bi-telephone-fill" /> +91 9996668116
                    </a>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>


      <a
        href="https://wa.me/+919996668116"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 8,
          right: 24,
          // backgroundColor: "#25d366",
          // color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          // boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
        <img src={whtsp} alt='whtsplogo' style={{ width: "66px" }}></img>
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>Fill in the Details to Get Started with BNYS Admissions 2023-24 at S-VYASA.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            {/* <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset> */}
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andaman & Nicobar">Andaman & Nicobar</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                  <option value="Daman & Diu">Daman & Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir">Jammu & Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>

                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BscNursing;