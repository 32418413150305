import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import logo from 'src/assets/assets/img/logo1.png';
import AOS from "aos";
import "aos/dist/aos.css";
import './style.css';
import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const KarnatakaBscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    name: '',
    email: '',
    contact: '',
    location: '',
    description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    };
  };
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const onSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    // formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', "B.Sc Nursing Entrance Exam 2025-26");
    formData.append('page', 'Landing Page');

    try {
      const response = await axios.post(
        'api/website/home/landingpageenquiry',
        formData
      );
      // console.log('response: ', response);

      if (response.data.status) {
        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error'
      });
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Helmet>
        <title>Karnataka B.Sc Nursing Entrance Exam 2025-26</title>

        <meta
          name="description"
          content="Know all about the Karnataka B.Sc Nursing Entrance Exam: Latest Updates, Exam Details, Application, Schedule, Admit Card, Exam Date, etc. Explore Now!"
        />
        <link
          rel="canonical"
          href="https://bangalorestudy.com/bsc-nursing-entrance-exams-in-karnataka"
        />

        <link
          rel="stylesheet"
          href="preprod/nursing_admission/assets/css/style.min.css"
        />
        <link
          rel="stylesheet"
          href="preprod/nursing_admission/assets/css/resp.min.css"
        />
        <link
          href="preprod/nursing_admission/assets/images/fav.png"
          rel="shortcut icon"
          type="image/x-icon"
        />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="preprod/nursing_admission/assets/css/owl.theme.default.min.css"
        />
        <link
          rel="stylesheet"
          href="preprod/nursing_admission/assets/css/dental.css"
        />

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Bangalore Study',
            image: `${pConfig.siteUrl}home_page/images/logo.png`,
            '@id': `${pConfig.siteUrl}contact`,
            url: `${pConfig.siteUrl}`,
            telephone: '+91-9036020016',
            priceRange: 'INR',
            address: {
              '@type': 'PostalAddress',
              streetAddress:
                "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
              addressLocality: 'Bengaluru',
              postalCode: '560069',
              addressCountry: 'IN'
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: 12.918769,
              longitude: 77.598285
            },
            openingHoursSpecification: {
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
              ],
              opens: '10:00',
              closes: '19:00'
            }
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Bangalore Study',
            legalName: 'Learntech Edu Solution Pvt.',
            url: `${pConfig.siteUrl}`,
            logo: `${pConfig.siteUrl}home_page/images/logo.png`,
            foundingDate: '2009',
            founders: [
              {
                '@type': 'Person',
                name: 'Mansoor Ali'
              }
            ],
            address: [
              {
                '@type': 'PostalAddress',
                addressLocality: 'Bangalore',
                addressRegion: 'IND',
                postalCode: '560069',
                streetAddress:
                  "#3/75, 'D' Main, East End,Jayanagar 9th Block, Bangalore, India"
              }
            ],
            contactPoint: {
              '@type': 'ContactPoint',
              contactType: 'customer support',
              telephone: '[+91-9036020016]',
              email: 'info@bangalorestudy.com'
            },
            sameAs: [
              'https://www.facebook.com/bangalorestudy/',
              'https://twitter.com/BangaloreStudy2',
              'https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w',
              'https://www.instagram.com/bangalorestudy/'
            ]
          })}
        </script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/slick.min.js"></script>
        <script
          type="text/javascript"
          src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"
        ></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script
          type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "
        ></script>

        <script>
          {`

$('.testi-slider-outer').slick({
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }

    ]
});

`}
        </script>
        <script type="text/javascript">
          {`

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);

$(document).ready(function () {
  $("a.fancybox ").fancybox()
});
setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      //college_name: $('#college_name').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "B.Sc Nursing Entrance Exam 2025-26",
      page: "Landing Page",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  
  $("#formoid2").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name2').val(),
      email: $('#email2').val(),
      contact: $('#contact2').val(),
      //college_name: $('#college_name2').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name2').val(),
      location: $('#location2').val(),
      sourse: "B.Sc Entrance Exam",
      description: $('#description2').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>

        <script>
          {` 
        var owl = $('.owl-carousel');
        owl.owlCarousel({
            loop: true,
            margin: 10,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items: 1,
                    nav: false
                },
                1000: {
                    items: 2,
                    nav: false
                }
            }
        }); 
      `}
        </script>
      </Helmet>
      <div className="col-md-12  desktop-view bsc-nurs">
        <div className="right-sec">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020076">
              <span>9036020076 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020076">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="outer-bg ">
        <div className="sec-with-right-form1">
          <div
            className="container-fluid mobile-box"
            style={{ padding: '0rem 6rem 0rem' }}
          >
            <a
              className="navbar-brand"
              href="https://bangalorestudy.com/"
              style={{ position: 'relative', zIndex: 100 }}
            >
              <div className="logo-sec">
                <img
                  src={logo}
                  width="200"
                  height="67"
                  style={{ marginLeft: 20 }}
                  alt="Bangalorestudy.com"
                />
              </div>
            </a>
            <div
              className="left-con-right-form aos-init aos-animate  "
              data-aos="zoom-out"
              data-aos-delay={100}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div className="slide-in left-con-sec slide-in m-0 mb-4" id="topcard">
                <div
                  className="content-container text-center"
                  id="backgroundclourtop"
                  style={{
                    background: 'rgba(29, 65, 90, 0.4) !important',
                    paddingTop: 10,
                    paddingBottom: 24,
                    // textAlign: "-webkit-match-parent",
                    borderRadius: 8,
                    paddingRight: 13,
                    paddingLeft: 13
                  }}
                >
                  <h1 style={{ color: 'white', fontSize: 41 }}>
                    Karnataka B.Sc Nursing Entrance Exam 2025-26
                  </h1>
                  <p
                    style={{
                      color: '#fff',
                      marginTop: 20,
                      marginBottom: 20,
                      fontSize: 25
                    }}
                  >
                    Want to make Nursing your career?
                    <br className="d-block" />
                    <br />
                    Curious to learn more about the Karnataka B.Sc Nursing
                    Entrance Examination?
                    <br />
                    <br />
                    WE’RE HERE FOR YOU!
                    <br />
                    <br />
                    Secure a seat in INC & KSNC approved colleges with
                    <br />
                    connected Hospitals/ Clinics through our guidance.
                  </p>
                  {/* <ul class="lead-divide mt-4">
                          <li>Get Admission Interview Counselling</li>
                          <li>Choose & Compare MBA Colleges</li>
                          <li>Admission Guidance By Experts</li>
                      </ul> */}
                  <div className="apply-btn">
                    {/* <Button variant="primary" onClick={handleShow}>
                      CONTACT US
                    </Button> */}

                    {/* <a
                      href="#form-module"
                      style={{ background: "#1d415a" }}
                      rel=" group "
                      className="fancybox "
                    >
                      Grab Your Seat Now!{" "}
                    </a> */}
                  </div>
                </div>
              </div>

              <div
                className=" slide-in2 form-box p-md-4 me-0 "
                style={{ backgroundColor: '#1d415a9c' }}
              >
                <div className="text-center">
                  <h3
                    className="p-0 text-center"
                    style={{
                      color: 'white',
                      fontSize: 19,
                      textTransform: 'capitalize'
                    }}
                  >
                    B.Sc Nursing Karnataka Entrance Exam{' '}
                    <br className="d-none d-md-block" />
                    2025 <br className="d-block d-md-none" />
                    Information Helpline Form
                  </h3>

                  <p className="font15" style={{ color: 'white', padding: 19 }}>
                    Fill in Your Details Now{' '}
                    <br className="d-block d-md-none" /> for More Informatio n!
                  </p>
                </div>

                <form
                  id="formoid"
                  action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                  title=""
                  method="post"
                >
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      required
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email ID*"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      className="form-control"
                      name="contact"
                      id="contact"
                      type="number"
                      required
                      placeholder="Contact No."
                    />
                  </div>
                  <div className="form-group mb-3">
                    <select
                      className="browser-default custom-select form-control"
                      name="location"
                      id="location"
                      required
                    >
                      <option value=" ">Select your State</option>
                      <option value="Andhra Pradesh ">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh ">
                        Arunachal Pradesh
                      </option>
                      <option value="Andaman & Nicobar ">
                        Andaman &amp; Nicobar
                      </option>
                      <option value="Assam ">Assam</option>
                      <option value="Bihar ">Bihar</option>
                      <option value="Chandigarh ">Chandigarh</option>
                      <option value="Chhattisgarh ">Chhattisgarh</option>
                      <option value="Dadra and Nagar Haveli ">
                        Dadra and Nagar Haveli
                      </option>
                      <option value="Daman & Diu ">Daman &amp; Diu</option>
                      <option value="Delhi ">Delhi</option>
                      <option value="Goa ">Goa</option>
                      <option value="Gujarat ">Gujarat</option>
                      <option value="Haryana ">Haryana</option>
                      <option value="Himachal Pradesh ">
                        Himachal Pradesh
                      </option>
                      <option value="Jammu & Kashmir ">
                        Jammu &amp; Kashmir
                      </option>
                      <option value="Jharkhand ">Jharkhand</option>
                      <option value="Karnataka ">Karnataka</option>
                      <option value="Kerala ">Kerala</option>
                      <option value="Lakshadweep ">Lakshadweep</option>
                      <option value="Madhya Pradesh ">Madhya Pradesh</option>
                      <option value="Maharashtra ">Maharashtra</option>
                      <option value="Manipur ">Manipur</option>
                      <option value="Meghalaya ">Meghalaya</option>
                      <option value="Mizoram ">Mizoram</option>
                      <option value="Nagaland ">Nagaland</option>
                      <option value="Odisha ">Odisha</option>
                      <option value="Punjab ">Punjab</option>
                      <option value="Puducherry ">Puducherry</option>
                      <option value="Rajasthan ">Rajasthan</option>
                      <option value="Sikkim ">Sikkim</option>
                      <option value="Tamil Nadu ">Tamil Nadu</option>
                      <option value="Tripura ">Tripura</option>
                      <option value="Telangana ">Telangana</option>
                      <option value="Uttarakhand ">Uttarakhand</option>
                      <option value="Uttar Pradesh ">Uttar Pradesh</option>
                      <option value="West Bengal ">West Bengal</option>
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <textarea
                      cols={3}
                      name="description"
                      id="description"
                      placeholder="Message (optional) "
                      className="form-control"
                      defaultValue={''}
                    />
                  </div>
                  <div className="form-group text-center">
                    <button className="button-nursing" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Course Section */}
        <div className="course-listing-outer p-0  ">
          <div className="container-fluid backblue">
            <div className="course-header  col-md-10 m-auto ">
              <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  // color: "#1D3355",
                  marginBottom: '0.5rem',
                  fontSize: 41,
                  color: '#F84365'
                }}
              >
                B.Sc Nursing Entrance Exam Made Mandatory from 2023-24
              </h2>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: 22,
                  color: 'white'
                }}
                className="cpadding"
              >
                The Karnataka State Government made B.Sc Nursing entrance exam
                mandatory for admissions to the course from the academic year
                2023-24. Candidates interested in pursuing B.Sc Nursing in
                Karnataka have to appear in the Common Entrance Test (CET)
                conducted by the Karnataka Examinations Authority (KEA) to be
                eligible for B.Sc Nursing admissions in colleges across the
                state.
              </p>
            </div>
          </div>
          <div className="container-fluid pb-5 ">
            <div className="course-header  col-md-10 m-auto">
              <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  color: '#1D3355',
                  marginBottom: '0.5rem',
                  fontSize: 41
                }}
              >
                Karnataka B.Sc Nursing Admissions 2025-26
              </h2>
              {/* <p style={{ textAlign: "center", fontWeight: 500 }} className="">
                The Karnataka State Government has made B.Sc Nursing entrance exam mandatory for admissions to the course from the academic year of 2023-24. The Karnataka Examinations Authority (KEA) will conduct the Common Entrance Test (CET) for B.Sc Nursing admissions in colleges across the state.
          
              </p> */}
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: 22,
                  color: 'black'
                }}
                className="pt-4 pb-0"
              >
                Here is the Karnataka B.Sc Nursing admission process broken down
                into 3 simple steps!
              </p>
            </div>
            <div
              className="course-liting-outer "
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 0.2fr 1fr 0.2fr 1fr',
                gridGap: '20px 8px'
              }}
            >
              <div className="course-inner ">
                <div className="course_icon">
                  <h2 className="steps">Step 1</h2>
                </div>
                <div className="course-ls-con ">
                  <div className="course-title p mt-3">
                    <h4 style={{ fontSize: 24 }}>
                      Register for B.Sc Nursing Entrance Exam 2025
                    </h4>
                  </div>
                </div>
                <div className="course-ls-con ">
                  <p style={{ fontSize: 19 }}>
                    Complete the{' '}
                    <a
                      href="https://cetonline.karnataka.gov.in/onlineapplication2025/forms/appchecklist.aspx"
                      target="_blank"
                      rel="nofollow"
                    >
                      KCET B.Sc application form 2025{' '}
                    </a>{' '}
                    on the official website.
                    <br />
                    <br />
                    Alternatively, let our experts get you registered for the
                    exam while you focus on preparation!
                  </p>
                  {/* <div className="text-center">
                    <span className='arrow'>&#8594;</span>
                  </div> */}
                </div>
              </div>
              <div className="text-center dis" style={{ alignSelf: 'center' }}>
                <span className="arrow">&#8594;</span>
              </div>
              <div className="course-inner ">
                <div className="course_icon">
                  <h2 className="steps">Step 2</h2>
                </div>
                <div className="course-ls-con ">
                  <div className="course-title p mt-3">
                    <h4 style={{ fontSize: 24 }}>
                      Write B.Sc Nursing Entrance Exam
                    </h4>
                  </div>
                </div>
                <div className="course-ls-con ">
                  <p style={{ fontSize: 19 }}>
                    Attend KCET 2025 B.Sc Nursing Entrance exam on the scheduled
                    date.
                    <br />
                    <br />
                    Do not forget to take a copy of your KCET B.Sc Nursing Admit
                    Card!
                  </p>
                  {/* <div className="text-center">
                    <span className='arrow'>&#8594;</span>
                  </div> */}
                </div>
              </div>
              <div className="text-center dis" style={{ alignSelf: 'center' }}>
                <span className="arrow">&#8594;</span>
              </div>
              <div className="course-inner ">
                <div className="course_icon">
                  <h2 className="steps">Step 3</h2>
                </div>
                <div className="course-ls-con ">
                  <div className="course-title p mt-3">
                    <h4 style={{ fontSize: 24 }}>
                      Attend B.Sc Nursing Counselling 2025-26
                    </h4>
                  </div>
                </div>
                <div className="course-ls-con ">
                  <p style={{ fontSize: 19 }}>
                    If you clear the B.Sc Nursing entrance exam and meet the
                    eligibility requirements, register for the KCET 2025 B.Sc
                    Nursing Counselling.
                    <br />
                    <br />
                    Get in touch with us to strategize your college choices and
                    secure B.Sc Nursing admission in the best Nursing colleges
                    in Karnataka!
                  </p>
                  {/* <div className="text-center">
                    <span className='arrow'>&#8594;</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid backblue">
            <div className="course-header  col-md-10 m-auto ">
              <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  // color: "#1D3355",
                  marginBottom: '0.5rem',
                  fontSize: 41,
                  color: '#F84365'
                }}
              >
                B.Sc Nursing Admissions 2025-26 Schedule
              </h2>
              <div className="table-responsive p-4">
                <table
                  className="table table-bordered text-center"
                  style={{ fontSize: 17, color: 'white' }}
                >
                  <thead>
                    <tr>
                      <th className="border align-middle" scope="col">
                        Event
                      </th>
                      <th className="border align-middle" scope="col">
                        Tentative Date{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border" scope="row">
                        KCET 2025 B.Sc Nursing Application Date Starts
                      </th>
                      <td className="border align-middle">
                        January 23, 2025 (After 11:00 AM)
                      </td>
                    </tr>
                    <tr>
                      <th className="border" scope="row">
                        KCET 2025 B.Sc Nursing Application Date Ends
                      </th>
                      <td className="border align-middle">
                        February 18, 2025 (11:59 PM){' '}
                      </td>
                    </tr>
                    <tr>
                      <th className="border" scope="row">
                        B.Sc Nursing Admit Card Release Date
                      </th>
                      <td className="border align-middle">
                        April 7, 2025 (11:00 AM) Onwards
                      </td>
                    </tr>
                    <tr>
                      <th className="border" scope="row">
                        KCET 2025 B.Sc Nursing Entrance Exam Date
                      </th>
                      <td className="border align-middle">
                        April 16 & 17, 2025
                      </td>
                    </tr>
                    <tr>
                      <th className="border" scope="row">
                        Result Announcement Date
                      </th>
                      <td className="border align-middle">To be announced</td>
                    </tr>
                    <tr>
                      <th className="border" scope="row">
                        Last Date for B.Sc Nursing Admissions 2025
                      </th>
                      <td className="border">To be announced</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: 22,
                  color: 'white'
                }}
                className="cpadding"
              >
                There was no compulsory entrance exam for securing a B.Sc
                Nursing seat in Karnataka till 2022-23 and candidates were
                allotted seats based on marks secured in 10+2/ 2nd PUC exams.
                From 2023-24 onwards, Nursing aspirants have to mandatorily
                write KCET and attend the state counselling to secure a B.Sc
                Nursing seat at any of the best nursing colleges in Karnataka.
                KEA KCET 2025 B.Sc Nursing Counselling will be conducted for
                both government and management quota seats.
              </p>
            </div>
          </div>

          <div className="container-fluid pb-0 ">
            <div className="course-header  col-md-10 m-auto pb-0 ">
              <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  color: '#1D3355',
                  marginBottom: '0.5rem',
                  fontSize: 45
                }}
              >
                B.Sc Nursing Entrance Exam Syllabus & Eligibility
              </h2>

              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: 22,
                  color: 'black'
                }}
                className="pt-4 pb-0"
              >
                Let’s take a look at some important details, such as the
                Karnataka B.Sc Nursing syllabus for entrance exam 2025 and the
                B.Sc Nursing entrance exam eligibility.
              </p>
              <div className="table-responsive p-4 ">
                <table
                  className="table table-bordered text-center"
                  style={{
                    fontSize: 17,
                    color: 'black',
                    backgroundColor: 'whitesmoke'
                  }}
                >
                  <tbody>
                    <tr>
                      <th className="border align-middle" scope="row">
                        Karnataka B.Sc Nursing{' '}
                        <br className="d-none d-md-block" /> Entrance Exam
                        Eligibility
                      </th>
                      <td
                        className="border align-middle"
                        style={{ color: 'black' }}
                      >
                        Candidates must have passed Physics, Chemistry,
                        <br className=" d-none d-md-block" />
                        Biology and English papers individually in 2nd
                        <br className="d-none d-md-block" /> PUC/ 10+2 and must
                        have secured a minimum of
                        <br className="d-none d-md-block" /> 45% aggregate marks
                        in PCB (40% for OBC/ SC/ ST).
                      </td>
                    </tr>
                    <tr>
                      <th className="border align-middle" scope="row">
                        Karnataka B.Sc Nursing{' '}
                        <br className="d-none d-md-block" />
                        Syllabus for Entrance
                        <br className="d-none d-md-block" /> Exam 2025
                      </th>
                      <td className="border align-middle">
                        The topics covered in Physics, Chemistry, and Biology
                        subjects at the higher secondary
                        <br className="d-md-block d-none" /> education level is
                        the syllabus of the B.Sc Nursing entrance exam (KCET -
                        2025).
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="container pt-5 mb-5 ">
                <div className="row cta-banner container  mx-md-auto p-md-2 m-2 mt-4 p-2 py-2 ">
                  <div className=" col-12 col-md-12  col-lg-8  cta-banner__text text-center">
                    Looking for detailed information about the
                    <br className="d-block d-md-block" />
                    syllabus of the B.Sc Nursing Entrance Exam 2025?
                  </div>
                  <div className="col-12 col-md-12 col-lg-4 pt-4 pt-md-1 pb-2 text-center">
                    <button className="red flat button" onClick={handleShow}>
                      Explore Syllabus Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid ">
            <div className="course-header  col-md-10 m-auto pt-0  ">
              <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  color: '#1D3355',
                  marginBottom: '0.5rem',
                  fontSize: 45
                }}
              >
                B.Sc Nursing Entrance Exam 2025 Tips
              </h2>
              <p style={{ textAlign: 'center', fontWeight: 500, fontSize: 22 }}>
                Aspirants must take certain important measures to secure a
                strong rank in the KCET-2025 B.Sc Nursing entrance examination.
                These steps must be followed right from when they begin to fill
                in the B.Sc Nursing application form 2025 until the completion
                of the KCET B.Sc Nursing counselling process. Below are the tips
                that one must follow to ensure the first step towards pursuing
                their dream of pursuing a career in nursing is smooth and
                efficient.
              </p>

              <div className='bsc-text-align' >
                <div className="custom-striped-container">
                  <div className="custom-striped-item" data-aos="fade-left">
                    Complete your KCET B.Sc Nursing application form 2025 well in advance to avoid errors and confusion.
                  </div>
                  <div className="custom-striped-item" data-aos="fade-right">
                    Make sure your exam preparation is in line with the B.Sc Nursing entrance exam syllabus.
                  </div>
                  <div className="custom-striped-item" data-aos="fade-left">
                    Take several B.Sc Nursing mock tests to get familiar with the exam pattern.
                  </div>
                  <div className="custom-striped-item" data-aos="fade-right">
                    Once the KCET 2025 B.Sc Nursing admit card is released, check your exam centre and plan on  how to reach it.
                  </div>
                  <div className="custom-striped-item" data-aos="fade-left">
                    Remember to carry your KCET B.Sc Nursing entrance exam admit card on the day of the exam.
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* End Course Section */}
        {/* collage Section */}
        <div className="course-listing-outer  pb-0 pt-0">
          <div className="container-fluid backblue pb-5">
            <div className="course-header  col-md-10 m-auto ">
              <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  marginBottom: '0.5rem',
                  fontSize: 41,
                  color: '#F84365'
                }}
              >
                Top Nursing Colleges in Bangalore for B.Sc Nursing
              </h2>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: 22,
                  color: 'white'
                }}
                className="cpadding"
              >
                Bangalore has a wide range of top nursing colleges that have some of the best nursing faculty in India. B.Sc Nursing is one of the popular courses chosen by students who are eager to enter the field of nursing. Students have to mandatorily clear the B.Sc Nursing entrance exam 2025 to get admission to any of the best nursing colleges in Bangalore.
                <br />
                <br />
                Below is a list of the top nursing colleges in Bangalore that offer B.Sc Nursing and other related courses that can elevate the students’ skills and knowledge necessary to pursue a successful career in the field of nursing.
              </p>
            </div>

            <div
              className="course-liting-outer gridpadding flex"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '25px 33px'
              }}
            >
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '422px' }}
              >
                <div className="course-img  ">
                  <img
                    src="preprod/nursing_admission/assets/images/Ramaiah-new.png"
                    alt="Ramaiah Institute of Nursing Education and Research (RINER)"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>
                      Ramaiah Institute of Nursing Education and Research
                      (RINER)
                    </h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 43 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 4px 0px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" /> MSR
                      Nagara, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '422px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/Kempegowda-new.png"
                    alt="Kempegowda Institute of Nursing (KIMS campus)"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon"
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>Kempegowda Institute of Nursing (KIMS campus)</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 43 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" /> VV
                      Puram, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '422px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/RajaRajeswari-new.png"
                    alt="RajaRajeswari College of Nursing (RRCN)"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>RajaRajeswari College of Nursing (RRCN)</h4>
                  </div>
                </div>
                <div
                  className="simple-des  nursing-simple-dos"
                  style={{ height: 43 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Kambipura, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '422px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/BGS GINS-new.png"
                    alt="BGS Global Institute of Nursing Science (BGS GINS)"
                    style={{ height: 146 }}
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>BGS Global Institute of Nursing Science (BGS GINS)</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 43 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Kengeri, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/SJBCON-new.png"
                    alt="SJB College of Nursing (SJBCON)"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>SJB College of Nursing (SJBCON)</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos"
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Kengeri, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/MVJ College-new.png"
                    alt="MVJ College of Nursing"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb"
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>MVJ College of Nursing</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos"
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Hoskote, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/Vydehi-new.png"
                    alt="Vydehi Institute of Nursing Sciences and Research Center (VINS)"
                    style={{ height: 105 }}
                  />
                </div>
                <div
                  className="course-ls-con nursing-simple-college"
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>
                      Vydehi Institute of Nursing Sciences and Research Center
                      (VINS)
                    </h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos"
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />
                      Whitefield, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/Ambedkar-new.png"
                    alt="Dr. B.R. Ambedkar Institute of Nursing"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>Dr. B.R. Ambedkar Institute of Nursing</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Kadugondanahalli, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/Oxford-new.png"
                    alt="The Oxford College of Nursing"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>The Oxford College of Nursing</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos"
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Hongasandra, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/Sapthagiri-new.png"
                    alt="Sapthagiri School and College of Nursing"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>Sapthagiri School of Nursing</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Chikkabanavara, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/DSU-new.png"
                    alt="College of Nursing Sciences, Dayananda Sagar University (DSU)"
                  />
                </div>
                <div
                  className="course-ls-con nursing-simple-college "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>
                      College of Nursing Sciences, Dayananda Sagar University
                      (DSU)
                    </h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Kumaraswamy Layout, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '440px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/East Point-new.png"
                    alt="East Point College of Nursing"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb "
                  style={{ height: 95 }}
                >
                  <div className="course-title ">
                    <h4>East Point College of Nursing</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Avalahalli, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
              <div
                className="course-inner bg-white "
                style={{ maxHeight: '406px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/PES-new.png"
                    alt="PES University"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb "
                  style={{ height: 53 }}
                >
                  <div className="course-title ">
                    <h4>PES University</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Banshankari, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>

              <div
                className="course-inner bg-white "
                style={{ maxHeight: '406px' }}
              >
                <div className="course-img ">
                  <img
                    src="preprod/nursing_admission/assets/images/Akash-new.png"
                    alt="Akash Institute of Nursing"
                  />
                </div>
                <div
                  className="course-ls-con nursing-icon-sjb "
                  style={{ height: 53 }}
                >
                  <div className="course-title ">
                    <h4> Akash Institute of Nursing</h4>
                  </div>
                </div>
                <div
                  className="simple-des nursing-simple-dos "
                  style={{ height: 66 }}
                >
                  <ul
                    style={{ listStyle: 'none', padding: '0px 18px 14px 14px' }}
                  >
                    <li>
                      <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                      Devanahalli, Bangalore
                    </li>

                    <li>
                      <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                      Private
                    </li>
                  </ul>
                </div>
                <div className="course-button-sec ">
                  <div className="right-btn  d-flex justify-content-center ">
                    <Button onClick={handleShow}>Apply Now</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="container-fluid  p-0 pb-0 pt-4" id="topdental">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center ">
                <h3 className="fh3MDS-dental">
                  {' '}
                  Top Nursing Colleges in Bangalore for B.Sc Nursing
                </h3>
              </div>

              <div className="col-lg-12 text-center pt-3 pb-3">
                <p className="textmbbs">
                  {' '}
                  Bangalore has a wide range of top Nursing colleges that have some of the best Nursing faculty in India. B.Sc Nursing is one of the popular courses chosen by students who are eager to enter the field of Nursing. Students have to mandatorily clear the B.Sc Nursing entrance exam 2025 to get admission to any of the best nursing colleges in Bangalore.
                </p>
              </div>

              <div className="col-lg-12 text-center pt-3 pb-3">
                <p className="textmbbs">
                  {' '}
                  Below is a list of the top Nursing colleges in Bangalore that offer B.Sc Nursing and other related courses that can elevate the student’s skills and knowledge necessary to pursue a successful career in the field of nursing.

                </p>
              </div>
            </div>
            <div className="col-lg-12 text-center ">
              <div className="row row-cols-1 row-cols-md-4 g-4 bdslandgingcolleges bdscollegeimage mds">
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Ramaiah-new.png"
                      alt="Ramaiah Institute of Nursing Education and Research (RINER)"
                    />
                    <div className="card-body">
                      <h5 className="card-title ">
                        {' '}
                        Ramaiah Institute of Nursing Education and Research
                        (RINER)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp; MSR Nagara,     Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                        Apply Now
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Kempegowda-new.png"
                      alt="Kempegowda Institute of Nursing (KIMS campus)"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Kempegowda Institute of Nursing (KIMS campus)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp; VV Puram, <pre></pre>Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/RajaRajeswari-new.png"
                      alt="RajaRajeswari College of Nursing (RRCN)"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      RajaRajeswari College of Nursing (RRCN)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker"
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Kambipura,<pre></pre> Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                         Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/BGS GINS-new.png"
                      alt="BGS Global Institute of Nursing Science (BGS GINS)"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      BGS Global Institute of Nursing Science (BGS GINS)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Kengeri,<pre></pre> Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/SJBCON-new.png"
                      alt="SJB College of Nursing (SJBCON)"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      SJB College of Nursing (SJBCON)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;  Kengeri,<pre></pre> Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                      Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/MVJ College-new.png"
                      alt="MVJ College of Nursing"
                    />
                    <div className="card-body">
                      <h5 className="card-title">MVJ College of Nursing</h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;Hoskote,<pre></pre> Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                          Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Vydehi-new.png"
                      alt="Vydehi Institute of Nursing Sciences and Research Center (VINS)"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Vydehi Institute of Nursing Sciences and Research Center (VINS)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Whitefield, <pre></pre>Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                         Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Ambedkar-new.png"
                      alt="Dr. B.R. Ambedkar Institute of Nursing"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Dr. B.R. Ambedkar Institute of Nursing
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;  Kadugondanahalli, Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Oxford-new.png"
                      alt="The Oxford College of Nursing"
                    />
                    <div className="card-body">
                      <h5 className="card-title">The Oxford College of Nursing</h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;  Hongasandra, Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Sapthagiri-new.png"
                      alt="Sapthagiri School and College of Nursing"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Sapthagiri School of Nursing
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker redi "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Chikkabanavara, Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/DSU-new.png"
                      alt="College of Nursing Sciences, Dayananda Sagar University (DSU)"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      College of Nursing Sciences, Dayananda Sagar University (DSU)
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;  Kumaraswamy Layout, Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/East Point-new.png"
                      alt="East Point College of Nursing"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      East Point College of Nursing
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;Avalahalli, <pre></pre>Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/PES-new.png"
                      alt="PES University"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      PES University
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;   Banshankari, Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                       Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card h-100 justify-content-center align-items-center">
                    <img
                      className="card-img-top imgcontain"
                      src="preprod/nursing_admission/assets/images/Akash-new.png"
                      alt="Akash Institute of Nursing"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Akash Institute of Nursing
                      </h5>
                      <p className="card-text">
                        <i
                          className="fa fa-map-marker "
                          style={{ color: 'red' }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;     Devanahalli, Bangalore
                        <ul
                          style={{
                            listStyle: 'none',
                            padding: '0px 4px 0px 14px'
                          }}
                        >
                          <li>
                            <i className="fa fa-briefcase" aria-hidden="true" />{' '}
                            Private
                          </li>
                        </ul>
                      </p>
                      <Button
                        variant="primary"
                        className="btn btn-success m-2 btnmdscolour"
                        onClick={handleShow}
                      >
                         Apply Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12 text-center p-3 f700 "
              style={{ fontSize: 20 }}
              id="FAQs"
            >
              & Many More!
            </div>
          </div>
        </section> */}
        {/* End Course Section */}
        {/* </div> */}
        {/* contact us section */}

        {/* End contact us section */}
        {/* FAQ Section */}

        {/* End of FAQ Section */}
        {/* contact us section */}
        {/* <div class="want-more-outer " style="margin-top: 20px; ">
  <div class="container ">
      <div class="content-container ">
          
          <h2>Get in touch for more details to grab your seat!</h2>
          <div class="apply-btn ">
              <a href="#form-module " rel="group " target="_blank " class="fancybox ">Apply Now</a>
          </div>
      </div>
  </div>
    </div> */}
        {/* End contact us section */}

        {/* Testimonial section */}

        {/* End testimonial section */}
        {/* contact-us */}
        <div className="contact_bg">
          <div className="contact">
            <div className="container">
              <div className="right-form-sec ">
                <div className="form-heading ">
                  <h3
                    style={{
                      color: '#fff',
                      textAlign: 'center',
                      fontSize: 41,
                      paddingBottom: '2rem'
                    }}
                  >
                    NEED FURTHER GUIDANCE? CONTACT US
                  </h3>
                </div>
                <div className="form-sec ">
                  <form
                    id="formoid2"
                    style={{ width: '65%', margin: 'auto' }}
                    // className="form bs-focused-form"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    method="post"
                  >
                    {/* <fieldset>
                      <input type="text " id="name " name="name " placeholder="Name* " required>
                  </fieldset> */}
                    <fieldset className="form-group ">
                      <input
                        type="text"
                        id="name2"
                        name="name"
                        placeholder="Name* "
                        required
                      />
                      <input
                        name="contact2"
                        type="number"
                        id="contact2"
                        min={0}
                        required
                        placeholder="Contact No. * "
                        aria-required="true"
                      />
                    </fieldset>
                    <fieldset className="form-group ">
                      <input
                        type="email"
                        id="email2"
                        name="email"
                        placeholder="Email ID* "
                        required
                      />
                    </fieldset>
                    <fieldset className="form-group ">
                      {/* <input type="email " id="mail " name="email " placeholder="Email ID* "
                          required> */}
                      <select
                        name="location"
                        id="location2"
                        style={{ height: 50, padding: '0px 22px' }}
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu & Kashmir ">
                          Jammu &amp; Kashmir
                        </option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                      {/* <input name="current_url " type="hidden " required
                          value="https://bangalorestudy.com/bds-admission " /> */}
                    </fieldset>

                    <fieldset className="form-group ">
                      <textarea
                        // type="text"
                        rows={4}
                        cols={3}
                        name="description2"
                        id="description2"
                        placeholder="Message (optional) "
                        className="textarea"
                        defaultValue={''}
                      />
                    </fieldset>
                    <fieldset className="form-group ">
                      <button className="p-1" type="submit">
                        Submit
                      </button>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container align-item-start justify-content-between d-none d-md-flex">
            <div className="footer-left  col-md-8 ">
              <a href="https://bangalorestudy.com/">
                <div className="ft-left mb-3" style={{ marginBottom: 20 }}>
                  <img
                    src={logo}
                    width="200"
                    height="67"
                    alt="bangalorestudy "
                  />
                </div>
              </a>
              <p className="text-left" style={{ fontSize: 12 }}>
                {' '}
                © 2025 Bangalorestudy.com. All Rights Reserved.
              </p>
              {/* <ul class="d-flex px-0"> */}
              {/* <li> */}
              {/* <a href="#about"  rel="noopener noreferrer" class="">About </a> */}
              {/* </li> */}
              {/* <li> */}
              {/* <a href="/in/terms-of-use" rel="noopener noreferrer" class="">Terms of Use</a> */}
              {/* </li> */}
              {/* <li> */}
              {/* <a href="/in/privacy-policy" rel="noopener noreferrer" class="">Privacy Policy</a> */}
              {/* </li> */}
              {/* <li> */}
              {/* <a href="#faq" rel="noopener noreferrer" class="">Why Choose Us</a> */}
              {/* </li> */}
              {/* <li> */}
              {/* <a href="#testimonial"  rel="noopener noreferrer" class="">Testimonials</a> */}
              {/* </li> */}
              {/* </ul> */}
            </div>
            <div className="footer-right col-md-4 offeset-md-1  d-flex">
              <div className="social-unit col-md-5 w-100">
                <div>
                  <p className="f18" style={{ marginBottom: 10 }}>
                    Connect with us
                  </p>
                  <a
                    href="tel:08022454991 "
                    style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                    080-22454991
                  </a>
                  ,
                  <a
                    href="tel:08026631169 "
                    style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                    080-26631169
                  </a>
                  <br />
                  <div className="bs-phone " style={{ display: 'contents' }}>
                    <a
                      href="tel:18001208696 "
                      style={{ color: 'white', fontWeight: 400, fontSize: 14 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                      1800 120 8696(Toll Free)
                    </a>
                  </div>
                  <p>
                    Dubai :{' '}
                    <a
                      href="tel:970502436552 "
                      style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                      +971502436552{' '}
                    </a>
                    ,{' '}
                    <a
                      href="tel:971504955123 "
                      style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                      +971504955123{' '}
                    </a>
                  </p>
                  <p style={{ marginTop: 10, marginBottom: 14 }}>
                    <a
                      target="_blank "
                      href="https://www.facebook.com/bangalorestudy "
                      style={{ marginRight: 10 }}
                    >
                      <i className="fa fa-facebook" />
                    </a>
                    <a
                      target="_blank "
                      href="https://twitter.com/BangaloreStudy2 "
                      style={{ marginRight: 10 }}
                    >
                      <i className="fa-brands fa-x-twitter" />
                    </a>
                    <a
                      target="_blank "
                      href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                      style={{ marginRight: 10 }}
                    >
                      {' '}
                      <i className="fa fa-youtube" />
                    </a>
                    <a
                      target="_blank "
                      href="https://instagram.com/bangalorestudy "
                      style={{ marginRight: 10 }}
                    >
                      <i className="fa fa-instagram" />
                    </a>
                    <a
                      target="_blank "
                      href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                      style={{ marginRight: 10 }}
                    >
                      {' '}
                      <i className="fa fa-linkedin" />
                    </a>
                    <a
                      target="_blank "
                      href="https://in.pinterest.com/bangalorestudy7608/ "
                      style={{ marginRight: 10 }}
                    >
                      <i className="fa fa-pinterest" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container align-item-start justify-content-between d-md-none">
            <div className="footer-left pt-5 col-md-7 text-center">
              <a href="https://bangalorestudy.com/">
                <div
                  className="ft-left mb-3"
                  style={{ justifyContent: 'flex-start' }}
                >
                  <img
                    src={logo}
                    width="200"
                    height="67"
                    alt="bangalorestudy "
                  />
                </div>
              </a>
            </div>
            <div className="footer-right col-md-5 offeset-md-1 py-t d-flex">
              <div className="app-unit col-md-7  col-md-5 ">
                <div className="store-wrapper">
                  <p>Social Connect</p>
                  <a
                    target="_blank "
                    href="https://www.facebook.com/bangalorestudy "
                    style={{ marginRight: 10 }}
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    target="_blank "
                    href="https://twitter.com/BangaloreStudy2 "
                    style={{ marginRight: 10 }}
                  >
                    <i className="fa fa-twitter" />
                  </a>
                  <a
                    target="_blank "
                    href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                    style={{ marginRight: 10 }}
                  >
                    {' '}
                    <i className="fa fa-youtube" />
                  </a>
                  <a
                    target="_blank "
                    href="https://instagram.com/bangalorestudy "
                    style={{ marginRight: 10 }}
                  >
                    <i className="fa fa-instagram" />
                  </a>
                  <a
                    target="_blank "
                    href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                    style={{ marginRight: 10 }}
                  >
                    {' '}
                    <i className="fa fa-linkedin" />
                  </a>
                  <a
                    target="_blank "
                    href="https://in.pinterest.com/bangalorestudy7608/ "
                    style={{ marginRight: 10 }}
                  >
                    <i className="fa fa-pinterest" />
                  </a>
                </div>
              </div>
              <div className="social-unit col-md-5 mt-4">
                <div>
                  <p>Connect with us</p>
                  <a
                    href="tel:08022454991 "
                    style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    080-22454991
                  </a>
                  ,
                  <a
                    href="tel:08026631169 "
                    style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    080-26631169
                  </a>
                  <div className="bs-phone " style={{ display: 'contents' }}>
                    {' '}
                    <br />
                    <a
                      href="tel:18001208696 "
                      style={{ color: 'white', fontWeight: 400, fontSize: 14 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} />
                      1800 120 8696(Toll Free)
                    </a>
                  </div>
                  <p>
                    Dubai :{' '}
                    <a
                      href="tel:971502436552 "
                      style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                      +971502436552{' '}
                    </a>
                    ,{' '}
                    <a
                      href="tel:971504955123 "
                      style={{ color: 'white', fontSize: 14, fontWeight: 400 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} />{' '}
                      +971504955123{' '}
                    </a>
                  </p>
                </div>
              </div>
              <p className="">
                {' '}
                © 2025 Bangalorestudy.com. All Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
        {/* Footer */}
      </div>

      <a
        href="https://wa.me/+919036020076"
        style={{
          position: 'fixed',
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          backgroundColor: '#25d366',
          color: '#fff',
          borderRadius: 50,
          textAlign: 'center',
          fontSize: 44,
          boxShadow: '2px 2px 3px #999',
          zIndex: 1059
        }}
        target="_blank "
      >
        <i className="fa fa-whatsapp my-float " />
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="heading-popup ">
            <h2>Apply Now!</h2>
          </div>
          <form
            // id="formoid1"
            onSubmit={onSubmit}
          // className="form bs-focused-form"
          // action=" https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
          // method="post"
          >
            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name}
                onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact}
                onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email}
                onChange={set('email')}
              />
            </fieldset>

            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">
                <select
                  name="location"
                  id="location1"
                  value={values.location}
                  onChange={set('location')}
                  required
                >
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">
                    Andaman &amp; Nicobar
                  </option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>
                {/* <select
                  name="course_in_mind"
                  style={{ height: 50, padding: "0px 22px" }}
                  id="course_in_mind"
                  required
                  value={values.course_in_mind} onChange={set('course_in_mind')}
                >
                  <option value=" ">Interested Course</option>
                  <option value="General Nursing and Midwifery (GNM)">
                    General Nursing and Midwifery (GNM)
                  </option>
                  <option value="Bachelor of Science (B.Sc) in Nursing">
                    Bachelor of Science (B.Sc) in Nursing
                  </option>
                  <option value="Post Basic Bachelor of Science (P.B.B.Sc) in Nursing">
                    Post Basic Bachelor of Science (P.B.B.Sc) in Nursing
                  </option>
                  <option value="Master of Science (M.Sc) Nursing">
                    Master of Science (M.Sc) Nursing
                  </option>
                </select> */}
                {/* <input name="current_url " type="hidden " required
                      value="https://bangalorestudy.com/bds-admission " /> */}
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description}
                  onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" style={{ lineHeight: '25px' }}>
                  Submit
                </button>
              </fieldset>
            </fieldset>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <div className="apply-ft ">
        <div className="ft-apply">
          {/* <a href="#form-module " rel="group " target="_blank " class="fancybox ">Request Call Back</a> */}
          <div
            id="form-module"
            className="popup-frame"
            style={{ display: 'none' }}
          >
            <div className="heading-popup ">
              <h2>Apply Now!</h2>
            </div>
            <form action="dental-admission " method="post ">
              {/* <input type="hidden " name="_token " >                    */}
              <fieldset>
                <input
                  type="text "
                  id="name "
                  name="name "
                  placeholder="Name* "
                  required
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group group_field">
                <input
                  name="contact_number "
                  type="number"
                  min={0}
                  required
                  placeholder="Contact No. * "
                  aria-required="true"
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group group_field">
                <input
                  type="email "
                  id="mail "
                  name="email "
                  placeholder="Email ID* "
                  required
                />
              </fieldset>
              <fieldset className="form-group group_field">
                <small className="text-danger " />
                <fieldset className="form-group group_field">
                  <select name="location " id="location " required>
                    <option value=" ">--select state--</option>
                    <option value="Andhra Pradesh ">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh ">
                      Arunachal Pradesh
                    </option>
                    <option value="Assam ">Assam</option>
                    <option value="Bihar ">Bihar</option>
                    <option value="Chhattisgarh ">Chhattisgarh</option>
                    <option value="Goa ">Goa</option>
                    <option value="Gujarat ">Gujarat</option>
                    <option value="Haryana ">Haryana</option>
                    <option value="Himachal Pradesh ">Himachal Pradesh</option>
                    <option value="Jammu & Kashmir ">
                      Jammu &amp; Kashmir
                    </option>
                    <option value="Jharkhand ">Jharkhand</option>
                    <option value="Karnataka ">Karnataka</option>
                    <option value="Kerala ">Kerala</option>
                    <option value="Madhya Pradesh ">Madhya Pradesh</option>
                    <option value="Maharashtra ">Maharashtra</option>
                    <option value="Manipur ">Manipur</option>
                    <option value="Meghalaya ">Meghalaya</option>
                    <option value="Mizoram ">Mizoram</option>
                    <option value="Nagaland ">Nagaland</option>
                    <option value="Odisha ">Odisha</option>
                    <option value="Punjab ">Punjab</option>
                    <option value="Rajasthan ">Rajasthan</option>
                    <option value="Sikkim ">Sikkim</option>
                    <option value="Tamil Nadu ">Tamil Nadu</option>
                    <option value="Tripura ">Tripura</option>
                    <option value="Telangana ">Telangana</option>
                    <option value="Uttarakhand ">Uttarakhand</option>
                    <option value="Uttar Pradesh ">Uttar Pradesh</option>
                    <option value="West Bengal ">West Bengal</option>
                    <option value="Andaman & Nicobar ">
                      Andaman &amp; Nicobar
                    </option>
                    <option value="Chandigarh ">Chandigarh</option>
                    <option value="Dadra and Nagar Haveli ">
                      Dadra and Nagar Haveli
                    </option>
                    <option value="Daman & Diu ">Daman &amp; Diu</option>
                    <option value="Delhi ">Delhi</option>
                    <option value="Lakshadweep ">Lakshadweep</option>
                    <option value="Puducherry ">Puducherry</option>
                  </select>
                  <select
                    name="location "
                    style={{ height: 50, padding: '0px 22px' }}
                    id="location "
                    required
                  >
                    <option value=" ">Interested Course</option>
                    <option value="General Nursing and Midwifery (GNM)">
                      General Nursing and Midwifery (GNM)
                    </option>
                    <option value="Bachelor of Science (B.Sc) in Nursing">
                      Bachelor of Science (B.Sc) in Nursing
                    </option>
                    <option value="Post Basic Bachelor of Science (P.B.B.Sc) in Nursing">
                      Post Basic Bachelor of Science (P.B.B.Sc) in Nursing
                    </option>
                    <option value="Master of Science (M.Sc) Nursing">
                      Master of Science (M.Sc) Nursing
                    </option>
                  </select>
                  {/* <input name="current_url " type="hidden " required
                      value="https://bangalorestudy.com/bds-admission " /> */}
                </fieldset>
                <small className="text-danger " />
                <fieldset className="form-group ">
                  <input
                    type="text "
                    name="description "
                    placeholder="Message (optional) "
                  />
                </fieldset>
                <small className="text-danger " />
                <fieldset className="form-group ">
                  <button type="submit">Submit</button>
                </fieldset>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default KarnatakaBscNursing;
